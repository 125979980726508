/* eslint-disable */

export const stateToMonths = {
  'Января': 1,
  'Февраля': 2,
  'Марта': 3,
  'Апреля': 4,
  'Мая': 5,
  'Июня': 6,
  'Июля': 7,
  'Августа': 8,
  'Сентября': 9,
  'Октября': 10,
  'Ноября': 11,
  'Декабря': 12,

  'Студзеня': 1,
  'Лютага': 2,
  'Сакавіка': 3,
  'Красавіка': 4,
  'Мая': 5,
  'Чэрвеня': 6,
  'Ліпеня': 7,
  'Жніўня': 8,
  'Верасня': 9,
  'Кастрычніка': 10,
  'Лістапада': 11,
  'Снежня': 12,
};

export const numbersToMonths = {
  1: 'Января',
  2: 'Февраля',
  3: 'Марта',
  4: 'Апреля',
  5: 'Мая',
  6: 'Июня',
  7: 'Июля',
  8: 'Августа',
  9: 'Сентября',
  10: 'Октября',
  11: 'Ноября',
  12: 'Декабря',
};

export const stateToCity = {
  'Барановичи':'Баранавічы',
  'Барань':'Барань',
  'Белоозёрск':'Белаазёрск',
  'Белыничи':' Бялынічы',
  'Берёза':'Бяроза',
  'Березино':'Беразіно',
  'Берёзовка':'Бярозаўка',
  'Бобруйск':'Бабруйск',
  'Борисов':'Барысаў',
  'Браслав':'Браслаў',
  'Брест':'Брэст',
  'Буда-Кошелёво':'Буда-Кашалёва',
  'Быхов':'Быхаў',
  'Василевичи':'Васілевічы',
  'Верхнедвинск':'Верхнядзвінск',
  'Ветка':'Ветка',
  'Вилейка':'Вілейка',
  'Витебск':' Віцебск',
  'Волковыск':'Ваўкавыск',
  'Воложин':'Валожын',
  'Высокое':'Высокае',
  'Ганцевичи':' Ганцавічы',
  'Глубокое':'Глыбокае',
  'Гомель':'Гомель',
  'Горки':'Горкі',
  'Городок':'Гарадок',
  'Гродно':'Гродна',
  'Давид-Городок':'Давыд-Гарадок',
  'Дзержинск':'Дзяржынск',
  'Дисна':'Дзісна',
  'Добруш':'Добруш',
  'Докшицы':'Докшыцы',
  'Дрогичин':'Драгічын',
  'Дубровно':'Дуброўна',
  'Дятлово':'Дзятлава',
  'Ельск':' Ельск',
  'Жабинка':'Жабінка',
  'Житковичи':'Жыткавічы',
  'Жлобин':'Жлобін',
  'Жодино':'Жодзіна',
  'Заславль':'Заслаўе',
  'Иваново':'Іванава',
  'Ивацевичи':'Івацэвічы',
  'Ивье':' Іўе',
  'Калинковичи':'Калінкавічы',
  'Каменец':'Камянец',
  'Кировск':'Кіраўск',
  'Клецк':'Клецк',
  'Климовичи':'Клімавічы',
  'Кличев':'Клічаў',
  'Кобрин':'Кобрын',
  'Копыль':'Капыль',
  'Коссово':' Косава',
  'Костюковичи':'Касцюковічы',
  'Кричев':'Крычаў',
  'Круглое':'Круглае',
  'Крупки':'Крупкі',
  'Лепель':'Лепель',
  'Лида':' Ліда',
  'Логойск':'Лагойск',
  'Лунинец':' Лунінец',
  'Любань':'Любань',
  'Ляховичи':'Ляхавічы',
  'Малорита':'Маларыта',
  'Марьина Горка':'Мар’іна Горка',
  'Микашевичи':'Мікашэвічы',
  'Минск':'Мінск',
  'Миоры':'Міёры, Мёры',
  'Могилёв':'Магілёў',
  'Мозырь':'Мазыр',
  'Молодечно':'Маладзечна, Маладэчна',
  'Мосты':'Масты',
  'Мстиславль':'Мсціслаў',
  'Мядель':'Мядзел',
  'Наровля':'Нароўля',
  'Несвиж':'Нясвіж',
  'Новогрудок':'Навагрудак, Наваградак',
  'Новолукомль':'Новалукомль, Новалукомаль',
  'Новополоцк':'Наваполацк',
  'Орша':'Орша',
  'Осиповичи':'Асіповічы',
  'Островец':'Астравец',
  'Ошмяны':'Ашмяны',
  'Петриков':'Петрыкаў',
  'Пинск':' Пінск',
  'Полоцк':'Полацк',
  'Поставы':'Паставы',
  'Пружаны':'Пружаны',
  'Речица':'Рэчыца',
  'Рогачёв':'Рагачоў',
  'Светлогорск':'Светлагорск',
  'Свислочь':'Свіслач',
  'Сенно':'Сянно, Сенна',
  'Скидель':'Скідзель, Скідаль',
  'Славгород':'Слаўгарад',
  'Слоним':'Слонім',
  'Слуцк':'Слуцк',
  'Смолевичи':'Смалявічы',
  'Сморгонь':'Смаргонь',
  'Солигорск':'Салігорск',
  'Старые Дороги':'Старыя Дарогі',
  'Столбцы':'Стоўбцы',
  'Столин':'Столін',
  'Толочин':'Талачын',
  'Туров':' Тураў',
  'Узда':'Узда',
  'Фаниполь':'Фаніпаль',
  'Хойники':'Хойнікі, Хвайнікі',
  'Чаусы':'Чавусы',
  'Чашники':'Чашнікі',
  'Червень':'Чэрвень',
  'Чериков':' Чэрыкаў',
  'Чечерск':'Чачэрск',
  'Шклов':'Шклоў',
  'Щучин':'Шчучын',
};
