// global
export const BASE_NAME = '/';
export const SUCCESS = '_SUCCESS';
export const FAIL = '_FAIL';
export const START_LOAD_LANGUAGE = 'START_LOAD_LANGUAGE';
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

// popup
export const POPUP_OPEN = 'POPUP_OPEN';
export const POPUP_BLACK_LIST = 'POPUP_BLACK_LIST';
export const POPUP_DELETE_PROFILE = 'POPUP_DELETE_PROFILE';
export const POPUP_CLOSE = 'POPUP_CLOSE';
export const POPUP_SUCCESS_DELETE = 'POPUP_SUCCESS_DELETE';
export const POPUP_SUBSCRIBE = 'POPUP_SUBSCRIBE';

// users
export const LOAD_USERS = 'LOAD_USERS';
export const GET_NEXT_USER = 'GET_NEXT_USER';
export const GET_PREV_USER = 'GET_PREV_USER';
export const IMG_LOADED = 'IMG_LOADED';
export const REMOVE_USER_FROM_LIST = 'REMOVE_USER_FROM_LIST';
export const SET_USER_FROM_SLIDER = 'SET_USER_FROM_SLIDER';
export const INCREASE_PAGE = 'INCREASE_PAGE';
export const OPEN_USER_PROFILE_POPUP = 'OPEN_USER_PROFILE_POPUP';
export const CLOSE_USER_PROFILE_POPUP = 'CLOSE_USER_PROFILE_POPUP';
export const GET_USER_CURRENT_PHOTO = 'GET_USER_CURRENT_PHOTO';

// auth
export const CLEAR_AUTH = 'CLEAR_AUTH';
export const DEFAULT_SID = '';
export const REDIRECT_TO_AUTH = 'REDIRECT_TO_AUTH';
export const AUTH = 'AUTH';
export const AUTH_INPUT_CHANGE = 'AUTH_INPUT_CHANGE';
export const EVT_STATUS = 'EVT_STATUS';
export const RESET_EVT_STATUS = 'RESET_EVT_STATUS';
export const RECOVERY_SEND_PHONE = 'RECOVERY_SEND_PHONE';
export const START_RECOVERY_SEND = 'START_RECOVERY_SEND';

//subscription
export const START_SUB_SEND = 'START_SUB_SEND';
export const SUB_SEND = 'SUB_SEND';

// reg
export const PHONE_SEND = 'PHONE_SEND';
export const START_REG_SEND_LOAD = 'START_REG_SEND_LOAD';
export const REG_INPUT_CHANGE = 'REG_INPUT_CHANGE';
export const REG_LOGIN_SEND = 'REG_LOGIN_SEND';
export const START_REG_LOGIN_LOAD = 'START_REG_LOGIN_LOAD';
export const RESET_SUB_STATUS = 'RESET_SUB_STATUS';
export const START_REG_DATA_LOAD = 'START_REG_DATA_LOAD';
export const REG_DATA_SEND = 'REG_DATA_SEND';
export const REG_PHOTO_LOAD = 'REG_PHOTO_LOAD';
export const SHOW_REG_MENU = 'SHOW_REG_MENU';
export const SHOW_FORM = 'SHOW_FORM';
export const VK_DATA_LOAD = 'VK_DATA_LOAD';
export const OK_DATA_LOAD = 'OK_DATA_LOAD';
export const START_PHOTO_LOAD = 'START_PHOTO_LOAD';
export const FINISH_PHOTO_LOAD = 'FINISH_PHOTO_LOAD';

export const CANCEL_PHOTO_EDIT = 'CANCEL_PHOTO_EDIT';
export const CANCEL_SECOND_STEP = 'CANCEL_SECOND_STEP';
export const OPEN_PHOTO_EDITOR = 'OPEN_PHOTO_EDITOR';
export const CLOSE_PHOTO_EDITOR = 'CLOSE_PHOTO_EDITOR';
export const GO_TO_EDIT_SECOND_STEP = 'GO_TO_EDIT_SECOND_STEP';

// help
export const HELP_SEND = 'HELP_SEND';
export const START_HELP_LOAD = 'START_HELP_LOAD';
export const HELP_INPUT_CHANGE = 'HELP_INPUT_CHANGE';
export const GET_CAPTCHA = 'GET_CAPTCHA';
export const NOT_VALID_NUMBER = 'NOT_VALID_NUMBER';

// logout
export const LOGOUT = 'LOGOUT';

// profile
export const START_PROFILE_LOAD = 'START_PROFILE_LOAD';
export const LOAD_OWN_EDIT = 'LOAD_OWN_EDIT';
export const LOAD_OWN_VIEW = 'LOAD_OWN_VIEW';
export const LOAD_EDITABLE_FIELDS = 'LOAD_EDITABLE_FIELDS';
export const LOAD_CITY_LIST = 'LOAD_CITY_LIST';
export const SETTINGS_FORM_CHANGE = 'SETTINGS_FORM_CHANGE';
export const CITY_CHANGE = 'CITY_CHANGE';
export const NAME_CHANGE = 'NAME_CHANGE';
export const ABOUT_CHANGE = 'ABOUT_CHANGE';
export const SELECT_CHANGE = 'SELECT_CHANGE';
export const AGE_FROM = 18;
export const AGE_TO = 98;
// export const CATEGORIES = [
//   'Книги', 'Фильмы', 'Спорт', 'Увлечения',
//   'Любимая кухня', 'Любимая музыка', 'Иностранные языки'];
export const CATEGORIES = [
  'category_book', 'category_films', 'category_sport', 'category_hobby',
  'category_kitchen', 'category_music', 'category_foreign'];
export const CATEGORIES_ENG = [
  'book', 'film', 'sport', 'hobby',
  'kitchen', 'music', 'foreign_lang',
];
export const CHANGE_ACTIVE_CATEGORY = 'CHANGE_ACTIVE_CATEGORY';
export const CITY_LIST_OPEN = 'CITY_LIST_OPEN';
export const CITY_LIST_CLOSE = 'CITY_LIST_CLOSE';
export const CITY_NAME_SELECTED = 'CITY_NAME_SELECTED';
export const SAVE_SETTINGS = 'SAVE_SETTINGS';
export const LOAD_VIEW_SHORT = 'LOAD_VIEW_SHORT';
export const LOAD_PHOTO_LIST = 'LOAD_PHOTO_LIST';
export const RESET_PHOTO_PAGE = 'RESET_PHOTO_PAGE';
export const RESET_PHOTO_LIST = 'RESET_PHOTO_LIST';
export const RESET_PROFILE_ERROR = 'RESET_PROFILE_ERROR';
export const CHANGE_ACTIVE_PHOTO = 'CHANGE_ACTIVE_PHOTO';
export const SAVE_MAIN_PHOTO = 'SAVE_MAIN_PHOTO';
export const START_LOAD_PHOTO = 'START_LOAD_PHOTO';
export const DELETE_PHOTO = 'DELETE_PHOTO';
export const ADD_PHOTO = 'ADD_PHOTO';
export const FILE_TYPES = ['jpg', 'jpeg', 'png'];
export const MAX_SIZE = 10485760 // 10 Мегабайт
export const MIN_SIZE = 11000;

// oferta
export const SUB_COST = ' 0,1 руб. в сутки (с НДС)';

// messages

export const START_MESSAGES_LOAD = 'START_MESSAGES_LOAD';
export const MESSAGES_LOAD = 'MESSAGES_LOAD';
export const RESET_MESSAGES_PAGE = 'RESET_MESSAGES_PAGE';

// user
export const START_USER_INFO_LOAD = 'START_USER_INFO_LOAD';
export const SET_USER_ID = 'SET_USER_ID';
export const LOAD_USER_VIEW = 'USER_VIEW_LOAD';
export const LOAD_USER_PHOTO = 'LOAD_USER_PHOTO';
export const GET_CURRENT_PHOTO = 'GET_NEXT_PHOTO';
export const RESET_USER_PAGE = 'RESET_USER_PAGE';
export const SEND_LIKE = 'SEND_LIKE';
export const ADD_BLACK_LIST = 'ADD_BLACK_LIST';
export const SET_PHOTOS_FROM_MAIN = 'SET_PHOTOS_FROM_MAIN';
export const USER_VIEW_FROM_MAIN_LOAD = 'USER_VIEW_FROM_MAIN_LOAD';

// conversation
export const START_CONVERSATION_LOAD = 'START_CONVERSATION_LOAD';
export const LOAD_CONVERSATION_VIEW = 'LOAD_CONVERSATION_VIEW';
export const SET_CONVERSATION_USER_ID = 'SET_CONVERSATION_USER_ID';
export const RESET_CONVERSATION_PAGE = 'RESET_CONVERSATION_PAGE';
export const RESET_CONVERSATION_PAGE_LANGUAGE = 'RESET_CONVERSATION_PAGE_LANGUAGE';
export const LOAD_MORE_MESSAGES = 'LOAD_MORE_MESSAGES';
export const START_LOAD_MESSAGES = 'START_LOAD_MESSAGES';
export const CONVERSATION_INPUT_CHANGE = 'CONVERSATION_INPUT_CHANGE';
export const START_SEND_MESSAGE = 'START_SEND_MESSAGE';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const ADD_EMOJI = 'ADD_EMOJI';
export const ADD_CHAT_PHOTO = 'ADD_CHAT_PHOTO';
export const REMOVE_CHAT_PHOTO = 'REMOVE_CHAT_PHOTO';
export const CHANGE_EMOJI_FIELD_STATE = 'CHANGE_EMOJI_FIELD_STATE';
export const START_RECORDING = 'START_RECORDING';
export const STOP_RECORDING = 'STOP_RECORDING';
export const REMOVE_VOICE = 'REMOVE_VOICE';
export const SET_LOCATION_COORDS = 'SET_LOCATION_COORDS';
export const SET_LOCATION_TYPE = 'SET_LOCATION_TYPE';
export const SET_PHOTO_TYPE = 'SET_PHOTO_TYPE';
export const SET_TEXT_TYPE = 'SET_TEXT_TYPE';
export const SCREEN_LOGGER = 'SCREEN_LOGGER';
export const FOCUS_INPUT = 'FOCUS_INPUT';
export const BLUR_INPUT = 'BLUR_INPUT';
export const SET_CARET_POSITION = 'SET_CARET_POSITION';
export const CHECK_IS_MESSAGE = 'CHECK_IS_MESSAGE';
export const OPEN_STREAM = 'OPEN_STREAM';
export const GET_MESSAGE_FROM_STREAM = 'GET_MESSAGE_FROM_STREAM';
export const SCROLL_TO_BOTTOM = 'SCROLL_TO_BOTTOM';
export const CANCEL_SCROLL_TO_BOTTOM = 'CANCEL_SCROLL_TO_BOTTOM';
export const MAX_VOICE_TIME = 60000;

// settings
export const START_SETTINGS_LOAD = 'START_SETTINGS_LOAD';
export const LOAD_SETTINGS_DATA = 'LOAD_SETTINGS_DATA';
export const CHANGE_SMS_SETTING = 'CHANGE_SMS_SETTING';
export const SAVE_SETTINGS_DATA = 'SAVE_SETTINGS_DATA';
export const DELETE_PROFILE = 'DELETE_PROFILE';

// search
export const RESET_SEARCH = 'RESET_SEARCH';
export const OPEN_SEARCH = 'OPEN_SEARCH';
export const CLOSE_SEARCH = 'CLOSE_SEARCH';
export const AGE_SELECT_CHANGE = 'AGE_SELECT_CHANGE';
export const SEX_SEARCH_CHANGE = 'SEX_SEARCH_CHANGE';
export const GEO_SEARCH_CHANGE = 'GEO_SEARCH_CHANGE';
export const START_SEARCH_DATA_LOAD = 'START_SEARCH_DATA_LOAD';
export const SEARCH_DATA_SEND = 'SEARCH_DATA_SEND';
export const FIRST_SEARCH_DATA_SEND = 'FIRST_SEARCH_DATA_SEND';
export const FIRST_EXT_SEARCH_DATA_SEND = 'FIRST_EXT_SEARCH_DATA_SEND';
export const EXT_SEARCH_DATA_SEND = 'EXT_SEARCH_DATA_SEND';

export const OFERTA_START_LOAD = 'OFERTA_START_LOAD';
export const OFERTA_LOAD = 'OFERTA_LOAD';

export const filters = [
  'filter-normal',
  'filter-aden',
  'filter-amaro',
  'filter-clarendon',
  'filter-crema',
  'filter-gingham',
  'filter-hefe',
  'filter-hudson',
  'filter-inkwell',
  'filter-juno',
  'filter-lark',
  'filter-lofi',
  'filter-ludwig',
  'filter-mayfair',
  'filter-moon',
  'filter-nashville',
  'filter-perpetua',
  'filter-poprocket',
  'filter-reyes',
  'filter-rise',
  'filter-sierra',
  'filter-slumber',
  'filter-valencia',
  'filter-willow',
  'filter-xpro-ii',
];

export const settings = [
  {
    name: 'contrast',
    ruName: 'Контраст',
    rbName: 'Кантраст',
    value: '100%',
  },
  {
    name: 'hue',
    ruName: 'Оттенок',
    rbName: 'Адценне',
    value: '0deg',
  },
  {
    name: 'brightness',
    ruName: 'Яркость',
    rbName: 'Яркасць',
    value: '100%',
  },
  {
    name: 'saturate',
    value: '100%',
    ruName: 'Насыщенность',
    rbName: 'Насычанасць',
  },
  // {
  //   name: 'sepia',
  //   value: '0%',
  //   ruName: 'Сепия',
  //   rbName: 'Сэпія',
  // },
];

export const extDescMap = {
  zodiac_desc: 'Гороскоп',
  year_desc: 'Восточный календарь',
  yearsum_desc: 'Нумерология',
};

export const extDescMapForRequest = {
  zodiac_desc: '1',
  year_desc: '2',
  yearsum_desc: '3',
};
