import {
  SUCCESS,
  FAIL,
  PHONE_SEND,
  REG_INPUT_CHANGE,
  START_REG_SEND_LOAD,
  REG_LOGIN_SEND,
  START_REG_LOGIN_LOAD,
  RESET_SUB_STATUS,
  REG_PHOTO_LOAD,
  START_REG_DATA_LOAD,
  REG_DATA_SEND,
  SHOW_FORM,
  SHOW_REG_MENU,
  OK_DATA_LOAD,
  VK_DATA_LOAD,
  START_PHOTO_LOAD,
  FINISH_PHOTO_LOAD,
} from '../constants';

const initialState = {
  pass: '',
  name: '',
  errors: [],
  message: '',
  isReg: false,
  isPhoneSend: true,
  isRegLoginSend: true,
  isRegRequestLoad: true,
  isRegDataSend: false,
  isFormActive: false,
  isSub: true,
  regData: {
    sex: '1',
  },
  isFromSocial: false,
  isPhotoInputLoad: true,
  isRegPhotoLoad: false,
};

const checkLoginStatus = states => !(states.indexOf(109) !== -1);

const checkRegStatus = state => (state === 100);

export default (state = initialState, action) => {
  switch (action.type) {
    case START_REG_SEND_LOAD:
      return { ...state, isRegRequestLoad: false };

    case PHONE_SEND + SUCCESS:
      if (checkRegStatus(action.payload.status)) {
        return {
          ...state,
          isRegRequestLoad: true,
          isReg: true,
        };
      }

      return { ...state, isPhoneSend: true, isRegRequestLoad: true };

    case PHONE_SEND + FAIL:
      return { ...state, isRegRequestLoad: true, message: 'Возникла ошибка, попробуйте ещё раз' };

    case START_REG_LOGIN_LOAD:
      return { ...state, isRegRequestLoad: false };

    case REG_LOGIN_SEND + SUCCESS:
      if (checkLoginStatus(action.payload.userStates)) {
        return {
          ...state,
          isRegRequestLoad: true,
          isRegLoginSend: true,
        };
      }

      return {
        ...state,
        isRegRequestLoad: true,
        isSub: false,
      };

    case REG_LOGIN_SEND + FAIL:
      return { ...state, isRegRequestLoad: true, message: 'Возникла ошибка, попробуйте ещё раз' };

    case RESET_SUB_STATUS:
      return { ...state, isSub: true };

    case REG_INPUT_CHANGE:
      const { value, fieldName } = action.payload;

      if (fieldName === 'name') {
        return {
          ...state,
          [fieldName]: value,
          regData: {
            ...state.regData,
            [fieldName]: value,
          },
        };
      }

      return { ...state, [fieldName]: value };

    case REG_PHOTO_LOAD:
      return {
        ...state,
        regData: {
          ...state.regData,
          photo: action.payload,
        },
        isRegPhotoLoad: true,
      };

    case START_REG_DATA_LOAD:
      return {
        ...state,
        isRegRequestLoad: false,
      };

    case REG_DATA_SEND + SUCCESS:
      return {
        ...state,
        isRegDataSend: true,
        isRegRequestLoad: true,
      };

    case SHOW_FORM:
      return {
        ...state,
        isFormActive: true,
      };

    case SHOW_REG_MENU:
      return {
        ...state,
        isPhoneSend: true,
        isRegLoginSend: true,
      };

    case OK_DATA_LOAD + SUCCESS:
      const okData = action.payload;

      return {
        ...state,
        isFromSocial: true,
        regData: {
          ...state.regData,
          name: okData.first_name,
          sex: (okData.gender === 'male') ? '1' : '2',
        },
      };

    case VK_DATA_LOAD + SUCCESS:
      const vkData = action.payload;

      return {
        ...state,
        isFromSocial: true,
        regData: {
          ...state.regData,
          name: vkData.first_name,
          sex: (vkData.sex === 2) ? '1' : '2',
        },
      };


    case REG_DATA_SEND + FAIL:
      return {
        ...state,
        isRegRequestLoad: true,
        message: 'Возникла ошибка, попробуйте ещё раз',
      };

    case START_PHOTO_LOAD:
      return {
        ...state,
        isPhotoInputLoad: false,
      };

    case FINISH_PHOTO_LOAD:
      return {
        ...state,
        isPhotoInputLoad: true,
      };

    default:
      return state;
  }
};
