import Cookies from 'js-cookie';
import _ from 'lodash';
import { DEFAULT_SID } from '../constants';

const parseURL = () => {
  // Получаем часть строки после символа "?" и разбиваем ее в массив по символу "&"
  const parts = window.location.search.slice(1).split('&');
  const parsedURL = {};

  _.forEach(parts, (part) => {
    const [key, value] = part.split('=');
    parsedURL[key] = value;
  });

  return parsedURL;
};

const getSidFromCookie = () => {
  // Пробуем считать из cookie
  if (Cookies.get('SID')) {
    return Cookies.get('SID');
  }
  // Если нет возвращаем дефолтный
  return DEFAULT_SID;
};

const handleParams = (parsedURL) => {
  // Если в распарсенном URL есть SID
  if (Object.prototype.hasOwnProperty.call(parsedURL, 'sid')) {
    return parsedURL.sid;
  }
  if (Object.prototype.hasOwnProperty.call(parsedURL, 'SID')) {
    return parsedURL.SID;
  }
  // Если в распарсенном SID отсутствует, то пробуем взять из cookie
  return getSidFromCookie();
};

export const getSID = () => {
  // Если в URL есть символ "?" после которого идут значения
  if (window.location.search !== '') {
    // Пробуем получить сид из распрасенного URL.
    return handleParams(parseURL());
  }
  // Если в URL нет символа "?" пробуем взять SID из cookie
  return getSidFromCookie();
};

export const hasResultInParams = () => {
  const params = parseURL();

  return !!params.result;
};
