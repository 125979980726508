import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'babel-polyfill';
import 'core-js/features/array';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import 'url-search-params-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import 'normalize.css';
import './scss/index.scss';

import Root from './components/Root/Root';

ReactDOM.render(
  <Root />,
  document.getElementById('root'),
);
