import _ from 'lodash';

import {
  SUCCESS,
  FAIL,
  START_USER_INFO_LOAD,
  LOAD_USER_VIEW,
  LOAD_USER_PHOTO,
  GET_CURRENT_PHOTO,
  RESET_USER_PAGE,
  IMG_LOADED,
  SEND_LIKE,
  SET_USER_ID,
  ADD_BLACK_LIST,
  SET_PHOTOS_FROM_MAIN,
  USER_VIEW_FROM_MAIN_LOAD
} from '../constants';


const initialState = {
  userView: {},
  userPhotoList: {},
  photoList: [],
  currentUserId: '',
  currentImg: '',
  currentImgIndex: 0,
  photoCnt: 0,
  currentPhotoId: '',
  isUserInfoLoad: false,
  isImgLoad: false,
  hasPhoto: false,
  isError: false,
  isMutualLike: false,
  isBlocked: false,
};

const findCurrentPhoto = (photoList, photoId) => {
  return _.findIndex(photoList, el => el.photo_id === photoId);
};

export default (state = initialState, action) => {
  switch (action.type) {
    case START_USER_INFO_LOAD:
      return {
        ...state,
        isUserInfoLoad: false,
      };

    case SET_USER_ID:
      return {
        ...state,
        currentUserId: action.payload,
      };

    case LOAD_USER_VIEW + SUCCESS:
      return {
        ...state,
        userView: action.payload,
        currentUserId: action.payload,
        isMutualLike: action.payload.isMutualLike,
      };

    case LOAD_USER_VIEW + FAIL:
      return {
        ...state,
        isError: true,
      };

    case LOAD_USER_PHOTO + SUCCESS:
      if (!action.payload.urls_small[0]) {
        return {
          ...state,
          hasPhoto: false,
          isUserInfoLoad: true,
        };
      }

      return {
        ...state,
        userPhotoList: action.payload,
        photoList: action.payload.urls_small,
        currentImg: action.payload.urls_small[0].url,
        currentPhotoId: action.payload.urls_small[0].photo_id,
        photoCnt: action.payload.urls_small.length,
        hasPhoto: true,
        isUserInfoLoad: true,
        isImgLoad: true,
      };

    case USER_VIEW_FROM_MAIN_LOAD + SUCCESS:
      return {
        ...state,
        isUserInfoLoad: true,
      };

    case SET_PHOTOS_FROM_MAIN:
      return {
        ...state,
        currentImgIndex: action.payload.curPhotoIndex,
        photoList: action.payload.photos,
        currentImg: action.payload.photos[action.payload.curPhotoIndex].url,
        currentPhotoId: action.payload.photos[action.payload.curPhotoIndex].photo_id,
        photoCnt: action.payload.length,
        hasPhoto: true,
        isUserInfoLoad: true,
        isImgLoad: true,
      };

    case LOAD_USER_PHOTO + FAIL:
      return {
        ...state,
        isUserInfoLoad: true,
        isImgLoad: true,
        isError: true,
      };

    case GET_CURRENT_PHOTO:
      return {
        ...state,
        currentImgIndex: action.payload,
        currentImg: state.photoList[action.payload].url,
        currentPhotoId: state.photoList[action.payload].photo_id,
      };

    case IMG_LOADED:
      return { ...state, isImgLoad: true };

    case SEND_LIKE + SUCCESS:
      if (!_.isEmpty(state.photoList)) {
        state.photoList[findCurrentPhoto(state.photoList, state.currentPhotoId)].liked = true;
      }

      return {
        ...state,
        photoList: state.photoList,
        isMutualLike: !!action.payload.isMutualLike,
      };

    case SEND_LIKE + FAIL:
      return {
        ...state,
        isUserInfoLoad: true,
      };

    case ADD_BLACK_LIST + SUCCESS:
      return {
        ...state,
        isBlocked: true,
      };

    case ADD_BLACK_LIST + FAIL:
      return {
        ...state,
        isUserInfoLoad: true,
      };

    case RESET_USER_PAGE:
      return {
        userView: {},
        userPhotoList: {},
        currentUserId: '',
        currentImg: '',
        currentImgIndex: 0,
        photoCnt: 0,
        currentPhotoId: '',
        isUserInfoLoad: false,
        isImgLoad: true,
        isError: false,
        isMutualLike: false,
        isBlocked: false,
      };

    default:
      return state;
  }
};
