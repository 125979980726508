const config = {
  items: [],
  min_distance: 1,
  max_distance: 12,
  number_of_lines: 60,
  line_height: 40,
  line_spacing: 3,
  line_width: 2,
  line_color: '#000000'
};

const getRandomWave = () => {
  config.items = Array(config.max_distance - config.min_distance + 1).fill().map((_, idx) => 7 + idx);

  const svgns = "http://www.w3.org/2000/svg";
  const lines = [];

  for (let i = 1; i < config.number_of_lines; i++) {
    const y1 = config.items[Math.floor(Math.random() * config.items.length)];
    const y2 = config.line_height - y1;
    const x = i * config.line_spacing;
    const line = document.createElementNS(svgns, 'line');

    line.setAttributeNS(null, 'x1', x);
    line.setAttributeNS(null, 'y1', y1);
    line.setAttributeNS(null, 'x2', x);
    line.setAttributeNS(null, 'y2', y2);
    line.setAttributeNS(null, 'stroke-width', config.line_width);
    line.setAttributeNS(null, 'stroke', config.line_color);

    lines.push(line);
  }

  return lines;
};

export default getRandomWave;
