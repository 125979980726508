const host = 'http://b3w-ru-tele2-dating.afedorov.vps7032.mtu.immo';
// const host = 'http://b3w-ru-tele2-dating.afedorov.vps7032.mtu.immo';
const base = `/android`;

export default {
  host,
  users: `${base}/search/ByLiked?page`,
  login: `${base}/auth/login`,
  logout: `${base}/auth/logout`,
  phoneInput: `${base}/auth/PhoneInput`,
  registration: `${base}/auth/registration`,
  sub: `${base}/auth/subscribe`,
  recovery: `${base}/auth/Recovery`,
  help: `${base}/feedback`,
  captcha: `${base}/feedback/GetCaptcha?transparent=1`,
  ownInfo: `${base}/ownProfile/viewShort`,
  evtStatus: `${base}/ownProfile/EventsStatus`,
  ownEdit: `${base}/ownProfile/edit`,
  ownView: `${base}/ownProfile/view`,
  editableFields: `${base}/ownProfile/EditableFieldsAndPossibleValues`,
  dataSave: `${base}/ownProfile/dataSave`,
  cityList: `${base}/search/citylist`,
  viewShort: `${base}/ownProfile/viewShort`,
  ownPhotoList: `${base}/ownPhoto/index?without_default_img=1`,
  savePhoto: `${base}/ownPhoto/SetMain?photoid=`,
  deletePhoto: `${base}/ownPhoto/delete?photoid=`,
  addPhoto: `${base}/ownPhoto/addAllPhotoWeb`,
  messages: `${base}/message`,
  userView: `${base}/profile/view?nick=`,
  userPhotoList: `${base}/profile/photoList?nick=`,
  sendLike: `${base}/profile/PhotoLike?`,
  addBlackList: `${base}/blacklist/add?nick=`,
  conversation: `${base}/message/conversation?nick=`,
  postMessage: `${base}/message/PostAndRead`,
  postVoice: `${base}/message/sendVoice`,
  postPhoto: `${base}/message/sendPhoto`,
  postLocation: `${base}/message/sendLocation`,
  deleteProfile: `${base}/ownProfile/delete?confirm=YES`,
  search: `${base}/search/perform`,
  compatibleSearch: `${base}/search/compatibleresults`,
  oferta: `${base}/service/oferta`,
  authUrl: 'http://tele2.forflirt.ru/lp/flow/flirt2?source=service',
};
