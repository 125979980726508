/*eslint-disable */
export function utf8toutf16(text) {
  var bele = 'be';
  var bom = false;
  var base = 'hex';
  var space = true;

  var bytes = utf8ToBytes(text);

  /*
  var utf8Patterns = {
      '1': '0xxxxxxx',
      '2': '110xxxxx10xxxxxx',
      '3': '1110xxxx10xxxxxx10xxxxxx',
      '4': '11110xxx10xxxxxx10xxxxxx10xxxxxx'
  };
  */

  var codePoints = [];

  for (var i = 0; i < bytes.length; ) {
    var byte = bytes[i];
    if ((byte & bin("11110000")) == bin("11110000")) {
      // Four byte UTF8
      var byte4 = bytes[i];
      var byte3 = bytes[i+1];
      var byte2 = bytes[i+2];
      var byte1 = bytes[i+3];

      var codePoint = ((byte4 & bin("00000111")) << 18) |
        ((byte3 & bin("00111111")) << 12) |
        ((byte2 & bin("00111111")) << 6) |
        (byte1 & bin("00111111"));

      //console.log(4, codePoint.toString(2));

      i+=4;
    }
    else if ((byte & bin("11100000")) == bin("11100000")) {
      // Three byte UTF8
      var byte3 = bytes[i];
      var byte2 = bytes[i+1];
      var byte1 = bytes[i+2];

      var codePoint = ((byte3 & bin("00001111")) << 12) |
        ((byte2 & bin("00111111")) << 6) |
        (byte1 & bin("00111111"));

      //console.log(3, codePoint.toString(2));

      i+=3;
    }
    else if ((byte & bin("11000000")) == bin("11000000")) {
      // Two byte UTF8
      var byte2 = bytes[i];
      var byte1 = bytes[i+1];

      var codePoint = ((byte2 & bin("00011111")) << 6) |
        (byte1 & bin("00111111"));

      //console.log(2, codePoint.toString(2));

      i+=2;
    }
    else if ((byte & 0x80) == 0) {
      // Single byte UTF8

      var codePoint = byte;

      //console.log(1, codePoint.toString(2));

      i+=1;
    }
    else {
      throw new Error("Unrecognized byte at position " + (i+1));
    }

    codePoints.push(codePoint);
  }

  /*
  var retStr = '';
  for (var i = 0; i < codePoints.length; i++) {
      retStr += codePoints[i].toString(2);
      retStr += "\n";
  }
  return retStr;
  */

  var gucciUtf16 = [];

  for (var i = 0; i < codePoints.length; i++) {
    var codePoint = codePoints[i];
    var utf16 = codePointToUtf16(codePoint, bele);
    gucciUtf16.push(utf16);
  }

  if (bom) {
    if (bele == 'be') {
      gucciUtf16.unshift([0xfeff]);
    }
    else {
      gucciUtf16.unshift([0xfffe]);
    }
  }

  var ret = '';

  for (var i = 0; i < gucciUtf16.length; i++) {
    var utf16 = gucciUtf16[i];
    if (utf16.length == 1) { // two bytes
      var val = utf16[0];
      if (base == 'binary') {
        var binVal = val.toString(2);
        var padLen = 16;
        while (binVal.length < padLen) {
          binVal = "0" + binVal;
        }
        ret += binVal;
      }
      else if (base == 'octal') {
        var octVal = val.toString(8);
        ret += octVal;
      }
      else if (base == 'decimal') {
        var decVal = val.toString(10);
        ret += decVal;
      }
      else if (base == 'hex') {
        var hexVal = val.toString(16);
        var padLen = 4;
        while (hexVal.length < padLen) {
          hexVal = "0" + hexVal;
        }
        ret += hexVal;
      }
      else if (base == 'raw') {
        var char2 = (val & 0xff00) >> 8;
        var char1 = (val & 0x00ff);
        var str = String.fromCharCode(char2) + String.fromCharCode(char1);
        ret += str;
      }
    }
    else { // four bytes
      var val2 = utf16[0];
      var val1 = utf16[1];
      if (base == 'binary') {
        var binVal2 = val2.toString(2);
        var padLen = 16;
        while (binVal2.length < padLen) {
          binVal2 = "0" + binVal2;
        }
        ret += binVal2;

        var binVal1 = val1.toString(2);
        var padLen = 16;
        while (binVal1.length < padLen) {
          binVal1 = "0" + binVal1;
        }
        ret += binVal1;
      }
      else if (base == 'octal') {
        var octVal2 = val2.toString(8);
        ret += octVal2;
        var octVal1 = val1.toString(8);
        ret += octVal1;
      }
      else if (base == 'decimal') {
        var decVal2 = val2.toString(10);
        ret += decVal2;
        var decVal1 = val1.toString(10);
        ret += decVal1;
      }
      else if (base == 'hex') {
        var hexVal2 = val2.toString(16);
        var padLen = 4;
        while (hexVal2.length < padLen) {
          hexVal2 = "0" + hexVal2;
        }
        ret += hexVal2;
        var hexVal1 = val1.toString(16);
        var padLen = 4;
        while (hexVal1.length < padLen) {
          hexVal1 = "0" + hexVal1;
        }
        ret += hexVal1;
      }
      else if (base == 'raw') {
        var char4 = (val2 & 0xff00) >> 8;
        var char3 = (val2 & 0x00ff);
        var char2 = (val1 & 0xff00) >> 8;
        var char1 = (val1 & 0x00ff);
        var str = String.fromCharCode(char4) + String.fromCharCode(char3) +
          String.fromCharCode(char2) + String.fromCharCode(char1);
        ret += str;
      }
    }
    if (space) {
      if (i != gucciUtf16.length-1) {
        ret += " ";
      }
    }
  }

  return ret;
}

function bin(x) {
  return parseInt(x,2);
}

function codePointToUtf16(codePoint, byteOrder) {
  if (codePoint <= 0xffff) {
    var utf16 = [codePoint];
  }
  else {
    var minus10000 = codePoint - 0x10000;
    var byte2 = (minus10000 >> 10) + 0xD800;
    var byte1 = (minus10000 & bin("1111111111")) + 0xDC00;
    var utf16 = [byte2, byte1];
  }

  if (byteOrder == 'be') {
    // big endian by default, don't swap bytes
    return utf16;
  }
  else if (byteOrder == 'le') {
    // little endian, swap bytes
    if (codePoint <= 0xffff) {
      // two byte utf16: exchange AABB to BBAA
      var b2 = utf16[0] & 0xff00;
      var b1 = utf16[0] & 0x00ff;
      return [(b2 >> 8) | (b1 << 8)];
    }
    else {
      // four byte utf16: exchange AABB|CCDD to BBAA|DDCC
      var b4 = utf16[0] & 0xff00;
      var b3 = utf16[0] & 0x00ff;
      var b2 = utf16[1] & 0xff00;
      var b1 = utf16[1] & 0x00ff;

      return [((b4 >> 8) | (b3 << 8)), ((b2 >> 8) | (b1 << 8))];
    }
  }
  else {
    throw new Error("Unrecognized byte order request for conversion.");
  }
}

export function utf16toutf8(text) {
  function stripString (str) {
    return str.replace(/^\s+/, '').replace(/\s+$/, '');
  }
  function newlinesToSpaces (str) {
    return str.replace(/\n+/g, ' ');
  }
  function tabsToSpaces (str) {
    return str.replace(/\t+/g, ' ');
  }

  var input = stripString(text);
  input = newlinesToSpaces(input);
  input = tabsToSpaces(input);
  input = input.toLowerCase();

  var bom = 'be'; // todo: make this a configuration option
  if (/^feff/.test(input)) {
    bom = 'be';
  }
  else if (/^fffe/i.test(input)) {
    bom = 'le';
  }

  // drop bom markers
  input = input.replace(/^(feff|fffe)\s*/, '');

  var ret = '';

  var gucciUtf16 = input.split(/\s+/g);
  for (var i = 0; i < gucciUtf16.length; i++) {
    var utf16 = gucciUtf16[i];
    if (utf16.length == 4 || utf16.length == 8) {
      // good
    }
    else {
      if (utf16.length < 4) {
        var padLength = 4;
        while (utf16.length < 4) {
          utf16 = "0" + utf16;
        }
      }
      else if (utf16.length < 8) {
        var padLength = 8;
        while (utf16.length < 8) {
          utf16 = "0" + utf16;
        }
      }
    }

    if (utf16.length == 4) {
      utf16 = parseInt(utf16, 16);
      if (bom == 'le') {
        // convert to be
        var b2 = (utf16 & 0xff00) >> 8;
        var b1 = utf16 & 0x00ff;
        utf16 = (b1 << 8) | b2;
      }

      ret += String.fromCodePoint(utf16);
    }
    else if (utf16.length == 8) {
      var matches = utf16.match(/(....)(....)/);
      var high = matches[1];
      var low = matches[2];

      if (bom == 'le') {
        // convert to be
        var matches = high.match(/(..)(..)/);
        var b2 = matches[1];
        var b1 = matches[2];
        high = b1 + b2;

        var matches = low.match(/(..)(..)/);
        var b2 = matches[1];
        var b1 = matches[2];
        low = b1 + b2;
      }

      high = parseInt(high, 16);
      low = parseInt(low, 16);

      high -= 0xD800;
      high *= 0x400;

      low -= 0xDC00;

      var codePoint = high + low + 0x10000;

      if (codePoint !== -56376868) {
        ret += String.fromCodePoint(codePoint);
      }
    }
  }

  return ret;
}

function utf8ToBytes (text) {
  var bytes = [];
  var encoded = utf8encode(text);
  for (var i = 0; i < encoded.length; i++) {
    var byte = encoded[i].charCodeAt(0);
    bytes.push(byte);
  }
  return bytes;
}

var stringFromCharCode = String.fromCharCode;

// Taken from https://mths.be/punycode
function ucs2decode(string) {
  var output = [];
  var counter = 0;
  var length = string.length;
  var value;
  var extra;
  while (counter < length) {
    value = string.charCodeAt(counter++);
    if (value >= 0xD800 && value <= 0xDBFF && counter < length) {
      // high surrogate, and there is a next character
      extra = string.charCodeAt(counter++);
      if ((extra & 0xFC00) == 0xDC00) { // low surrogate
        output.push(((value & 0x3FF) << 10) + (extra & 0x3FF) + 0x10000);
      } else {
        // unmatched surrogate; only append this code unit, in case the next
        // code unit is the high surrogate of a surrogate pair
        output.push(value);
        counter--;
      }
    } else {
      output.push(value);
    }
  }
  return output;
}

// Taken from https://mths.be/punycode
function ucs2encode(array) {
  var length = array.length;
  var index = -1;
  var value;
  var output = '';
  while (++index < length) {
    value = array[index];
    if (value > 0xFFFF) {
      value -= 0x10000;
      output += stringFromCharCode(value >>> 10 & 0x3FF | 0xD800);
      value = 0xDC00 | value & 0x3FF;
    }
    output += stringFromCharCode(value);
  }
  return output;
}

function checkScalarValue(codePoint) {
  if (codePoint >= 0xD800 && codePoint <= 0xDFFF) {
    throw Error(
      'Lone surrogate U+' + codePoint.toString(16).toUpperCase() +
      ' is not a scalar value'
    );
  }
}
/*--------------------------------------------------------------------------*/

function createByte(codePoint, shift) {
  return stringFromCharCode(((codePoint >> shift) & 0x3F) | 0x80);
}

function encodeCodePoint(codePoint) {
  if ((codePoint & 0xFFFFFF80) == 0) { // 1-byte sequence
    return stringFromCharCode(codePoint);
  }
  var symbol = '';
  if ((codePoint & 0xFFFFF800) == 0) { // 2-byte sequence
    symbol = stringFromCharCode(((codePoint >> 6) & 0x1F) | 0xC0);
  }
  else if ((codePoint & 0xFFFF0000) == 0) { // 3-byte sequence
    checkScalarValue(codePoint);
    symbol = stringFromCharCode(((codePoint >> 12) & 0x0F) | 0xE0);
    symbol += createByte(codePoint, 6);
  }
  else if ((codePoint & 0xFFE00000) == 0) { // 4-byte sequence
    symbol = stringFromCharCode(((codePoint >> 18) & 0x07) | 0xF0);
    symbol += createByte(codePoint, 12);
    symbol += createByte(codePoint, 6);
  }
  symbol += stringFromCharCode((codePoint & 0x3F) | 0x80);
  return symbol;
}

function utf8encode(string) {
  var codePoints = ucs2decode(string);
  var length = codePoints.length;
  var index = -1;
  var codePoint;
  var byteString = '';
  while (++index < length) {
    codePoint = codePoints[index];
    byteString += encodeCodePoint(codePoint);
  }
  return byteString;
}

/*--------------------------------------------------------------------------*/

function readContinuationByte() {
  if (byteIndex >= byteCount) {
    throw Error('Invalid byte index');
  }

  var continuationByte = byteArray[byteIndex] & 0xFF;
  byteIndex++;

  if ((continuationByte & 0xC0) == 0x80) {
    return continuationByte & 0x3F;
  }

  // If we end up here, it�s not a continuation byte
  throw Error('Invalid continuation byte');
}

function decodeSymbol() {
  var byte1;
  var byte2;
  var byte3;
  var byte4;
  var codePoint;

  if (byteIndex > byteCount) {
    throw Error('Invalid byte index');
  }

  if (byteIndex == byteCount) {
    return false;
  }

  // Read first byte
  byte1 = byteArray[byteIndex] & 0xFF;
  byteIndex++;

  // 1-byte sequence (no continuation bytes)
  if ((byte1 & 0x80) == 0) {
    return byte1;
  }

  // 2-byte sequence
  if ((byte1 & 0xE0) == 0xC0) {
    byte2 = readContinuationByte();
    codePoint = ((byte1 & 0x1F) << 6) | byte2;
    if (codePoint >= 0x80) {
      return codePoint;
    } else {
      throw Error('Invalid continuation byte');
    }
  }

  // 3-byte sequence (may include unpaired surrogates)
  if ((byte1 & 0xF0) == 0xE0) {
    byte2 = readContinuationByte();
    byte3 = readContinuationByte();
    codePoint = ((byte1 & 0x0F) << 12) | (byte2 << 6) | byte3;
    if (codePoint >= 0x0800) {
      checkScalarValue(codePoint);
      return codePoint;
    } else {
      throw Error('Invalid continuation byte');
    }
  }

  // 4-byte sequence
  if ((byte1 & 0xF8) == 0xF0) {
    byte2 = readContinuationByte();
    byte3 = readContinuationByte();
    byte4 = readContinuationByte();
    codePoint = ((byte1 & 0x07) << 0x12) | (byte2 << 0x0C) |
      (byte3 << 0x06) | byte4;
    if (codePoint >= 0x010000 && codePoint <= 0x10FFFF) {
      return codePoint;
    }
  }

  throw Error('Invalid UTF-8 detected');
}

var byteArray;
var byteCount;
var byteIndex;
function utf8decode(byteString) {
  byteArray = ucs2decode(byteString);
  byteCount = byteArray.length;
  byteIndex = 0;
  var codePoints = [];
  var tmp;
  while ((tmp = decodeSymbol()) !== false) {
    codePoints.push(tmp);
  }
  return ucs2encode(codePoints);
}
