import Axios from 'axios';
import qs from 'qs';
import Cookies from 'js-cookie';

import {
  SUCCESS,
  FAIL,
  AUTH,
  REDIRECT_TO_AUTH,
  AUTH_INPUT_CHANGE,
  EVT_STATUS,
  RESET_EVT_STATUS,
  RECOVERY_SEND_PHONE,
  START_RECOVERY_SEND,
  DEFAULT_SID,
  CLEAR_AUTH,
  START_SUB_SEND,
  SUB_SEND,
  POPUP_OPEN,
  POPUP_SUBSCRIBE,
} from '../constants';
import { api } from '../util';
import { getSID } from '../util/parseURL';

export function login() {
  return (dispatch, getState) => {
    const { auth: { phone, pass } } = getState();
    const COOKIES_DATE = 7;
    const sid = getSID();
    const url = `${api.login}?SID=${sid}`;

    if (sid === DEFAULT_SID) {
      dispatch({
        type: REDIRECT_TO_AUTH,
      });
    } else {
      Cookies.set('SID', sid, { expires: COOKIES_DATE });
    }

    const urlParams = new URLSearchParams(window.location.search);

    const subId = (urlParams.get('sub_id_4')) ? `&sub_id_4=${urlParams.get('sub_id_4')}` : '';
    const lpId = (urlParams.get('lpid')) ? `&lpid=${urlParams.get('lpid')}` : '';
    const lpContext = (urlParams.get('lpcontext')) ? `&lpcontext=${urlParams.get('lpcontext')}` : '';
    const keyId = (urlParams.get('keyid')) ? `&keyid=${urlParams.get('keyid')}` : '';

    api.authUrl = api.authUrl + subId + lpId + lpContext + keyId;

    Axios
      .post(url, qs.stringify({
        token: `${phone.replace(/[^0-9]/g, '')}`,
        pass,
      }), { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: AUTH + SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: AUTH + FAIL,
          payload: err,
        });
      });
  };
}

export function recovery() {
  return (dispatch, getState) => {
    const { auth: { phone } } = getState();

    dispatch({
      type: START_RECOVERY_SEND,
    });

    Axios
      .post(api.recovery, qs.stringify({
        telnum: `${phone.replace(/[^0-9]/g, '')}`,
      }), { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: RECOVERY_SEND_PHONE + SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: RECOVERY_SEND_PHONE + FAIL,
          payload: err,
        });
      });
  };
}

export function checkEvtStatus() {
  return (dispatch) => {
    const COOKIES_DATE = 7;
    const sid = getSID();
    const url = `${api.evtStatus}?SID=${sid}`;

    if (sid === DEFAULT_SID) {
      dispatch({
        type: REDIRECT_TO_AUTH,
      });
    } else {
      Cookies.set('SID', sid, { expires: COOKIES_DATE });
    }

    Axios
      .get(url, { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: EVT_STATUS + SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: EVT_STATUS + FAIL,
          payload: err,
        });
      });
  };
}

export function resetEvtStatus() {
  return ({
    type: RESET_EVT_STATUS,
  });
}

export function clearAuth() {
  return ({
    type: CLEAR_AUTH,
  });
}

export function inputChange(evt) {
  return ({
    type: AUTH_INPUT_CHANGE,
    payload: {
      value: evt.target.value,
      fieldName: evt.target.dataset.name,
    },
  });
}

export function subscribe() {
  return (dispatch) => {
    dispatch({
      type: START_SUB_SEND,
    });

    Axios
      .get(api.sub, { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        if (data.status === 'OK' && data.userStates.indexOf(109) !== -1) {
          dispatch({
            type: POPUP_OPEN,
            payload: {
              popupTitle: 'popup_sub_fail_title',
              popupMessage: 'popup_sub_fail_message',
            },
          });
          dispatch({
            type: POPUP_SUBSCRIBE,
          });
        }
        dispatch({
          type: SUB_SEND + SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: SUB_SEND + FAIL,
          payload: err,
        });
      });
  };
}
