import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { api } from '../../util';

import './popup.scss';

import CloseIcon from '../Icons/CloseIcon';

import setLanguage from '../../util/setLanguage';
import closePopup from '../../actions/popup';
import { addToBlackList } from '../../actions/user';
import { deleteProfile } from '../../actions/settings';

class Popup extends Component {
  static propTypes = {
    popup: PropTypes.shape({
      popupTitle: PropTypes.string,
      popupMessage: PropTypes.string,
      isBlackList: PropTypes.bool,
      isDeleteProfile: PropTypes.bool,
      isSuccessDelete: PropTypes.bool,
    }),

    isFromMain: PropTypes.bool,

    closePopup: PropTypes.func,
    addToBlackList: PropTypes.func,
    deleteProfile: PropTypes.func,
  };

  render() {
    const { language } = this.props.language;

    const { notHaveSub, notFullReg, isRedirect } = this.props.auth;

    const {
      popupTitle, popupMessage, isBlackList, isDeleteProfile, isSuccessDelete, isSubscribe,
    } = this.props.popup;
    const {
      isFromMain, closePopup, addToBlackList, deleteProfile,
    } = this.props;

    return (
      <div className="popup">
        <div className="popup__dialog">
          <button className="popup__close-button" type="button" onClick={closePopup}>
            <CloseIcon />
          </button>
          <div className="popup__title">
            {setLanguage(language, popupTitle)}
          </div>
          {popupMessage && (
            <div className="popup__message">
              {setLanguage(language, popupMessage)}
            </div>
          )}

          {isFromMain && (isRedirect || notHaveSub) && (
            <div className="popup__extra-dialog">
              <div className="popup__message">
                {setLanguage(language, 'popup_main_message_second')}
              </div>
              <a className="popup__button button button--red" href={api.authUrl} onClick={closePopup}>
                {setLanguage(language, 'popup_main_second_button')}
              </a>

              <button className="popup__exit" type="button" onClick={closePopup}>
                {setLanguage(language, 'popup_main_cancel_button')}
              </button>
            </div>
          )}

          {isFromMain && (notFullReg && !notHaveSub) && (
            <div className="popup__extra-dialog">
              <div className="popup__message">
                {setLanguage(language, 'popup_main_message')}
              </div>
              <Link className="popup__button button button--red" to="/registration" onClick={closePopup}>
                {setLanguage(language, 'popup_main_first_button')}
              </Link>

              <button className="popup__exit" type="button" onClick={closePopup}>
                {setLanguage(language, 'popup_main_cancel_button')}
              </button>
            </div>
          )}

          {isBlackList && (
            <div className="popup__extra-dialog popup__extra-dialog--flex-row">
              <button className="popup__button popup__button--small button button--dark-grey" type="button" onClick={closePopup}>
                {setLanguage(language, 'popup_add_blacklist_cancel')}
              </button>
              <button className="popup__button popup__button--small button button--red" type="button" onClick={addToBlackList}>
                {setLanguage(language, 'popup_add_blacklist_confirm')}
              </button>
            </div>
          )}

          {isDeleteProfile && (
            <div className="popup__extra-dialog popup__extra-dialog--flex-row">
              <button className="popup__button popup__button--small button button--dark-grey" type="button" onClick={closePopup}>
                {setLanguage(language, 'popup_delete_cancel_button')}
              </button>
              <button className="popup__button popup__button--small button button--red" type="button" onClick={deleteProfile}>
                {setLanguage(language, 'popup_delete_confirm_button')}
              </button>
            </div>
          )}

          {isSuccessDelete && (
            <div className="popup__extra-dialog popup__extra-dialog--flex-row">
              <a className="popup__button popup__button--small button button--grey" href="/" onClick={closePopup}>
                ОК
              </a>
            </div>
          )}

          {isSubscribe && (
            <div className="popup__extra-dialog">
              <Link className="popup__button popup__button--small button button--red" to="/" onClick={closePopup}>
                ОК
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ popup, language, auth }) => ({ popup, language, auth });

const mapDispatchToProps = {
  closePopup,
  addToBlackList,
  deleteProfile,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Popup));
