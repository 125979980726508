import {
  SUCCESS,
  FAIL,
  LOAD_OWN_EDIT,
  LOAD_OWN_VIEW,
  LOAD_EDITABLE_FIELDS,
  LOAD_CITY_LIST,
  CITY_CHANGE,
  NAME_CHANGE,
  ABOUT_CHANGE,
  CITY_LIST_OPEN,
  CITY_LIST_CLOSE,
  CITY_NAME_SELECTED,
  CHANGE_ACTIVE_CATEGORY,
  SELECT_CHANGE,
  SAVE_SETTINGS,
  SETTINGS_FORM_CHANGE,
  RESET_PROFILE_ERROR,
  OK_DATA_LOAD,
  VK_DATA_LOAD,
} from '../constants';

import { stateToMonths } from '../util/dictionares';

const initialState = {
  isEditLoad: false,
  isViewLoad: false,
  isEditableFieldsLoad: false,
  isCityListLoaded: false,
  edit: {},
  view: {},
  editableFields: {},
  cityList: [],
  cityName: '',
  isFormChange: false,
  isCityListOpen: false,
  isCityIdChange: true,
  activeCategory: null,
  savingData: {
    birthday_day: 1,
    birthday_month: 1,
    birthday_year: 1990,
  },
  isError: false,
};

const cutCity = (fullName) => {
  const isFirstCharUpper = str => (str.charAt(0).toUpperCase() === str.charAt(0));
  const isStrHasParentheses = str => (str.indexOf('(') !== -1);

  const upper = isFirstCharUpper(fullName);
  const hasPar = isStrHasParentheses(fullName);

  if (upper && !hasPar) {
    return fullName;
  }

  if (upper && hasPar) {
    return fullName.split(' (')[0];
  }

  if (!upper && hasPar) {
    return fullName.split('. ')[1].split(' (')[0];
  }

  return '';
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOAD_OWN_EDIT + SUCCESS:
      if (action.payload.errors.length !== 0) {
        return {
          ...state,
          isError: true,
          isEditLoad: true,
        };
      }

      return {
        ...state,
        isEditLoad: true,
        edit: action.payload,
        savingData: {
          ...state.savingData,
          nick: action.payload.nick,
        },
      };

    case LOAD_OWN_EDIT + FAIL:
      return { ...state, isError: true };

    case LOAD_OWN_VIEW + SUCCESS:
      if (action.payload.errors.length !== 0) {
        return {
          ...state,
          isError: true,
          isViewLoad: true,
        };
      }

      return {
        ...state,
        isViewLoad: true,
        view: action.payload,
        cityName: action.payload.cityName,
      };

    case LOAD_OWN_VIEW + FAIL:
      return { ...state, isError: true };

    case LOAD_EDITABLE_FIELDS + SUCCESS:
      if (action.payload.errors) {
        return {
          ...state,
          isError: true,
          isEditableFieldsLoad: true,
        };
      }

      return { ...state, isEditableFieldsLoad: true, editableFields: action.payload };

    case LOAD_EDITABLE_FIELDS + FAIL:
      return { ...state, isError: true };

    case LOAD_CITY_LIST + SUCCESS:
      return { ...state, cityList: action.payload, isCityListLoaded: true };

    case LOAD_CITY_LIST + FAIL:
      return { ...state, isError: true };

    case SETTINGS_FORM_CHANGE:
      const fields = action.payload;

      return {
        ...state,
        isFormChange: true,
        savingData: {
          ...state.savingData,
          book: fields.book,
          film: fields.film,
          sport: fields.sport,
          hobby: fields.hobby,
          kitchen: fields.kitchen,
          music: fields.music,
          foreign_lang: fields.foreign_lang,
        },
      };

    case CITY_CHANGE + SUCCESS:
      return {
        ...state,
        cityName: action.payload.value,
        isCityIdChange: false,
      };

    case CITY_CHANGE + FAIL:
      return {
        ...state,
        cityName: action.payload.value,
        isCityIdChange: false,
      };

    case NAME_CHANGE:
      return {
        ...state,
        savingData: {
          ...state.savingData,
          name: action.payload,
        },
      };

    case ABOUT_CHANGE:
      return {
        ...state,
        savingData: {
          ...state.savingData,
          greeting: action.payload,
        },
      };

    case SELECT_CHANGE:
      const selectFields = action.payload;

      return {
        ...state,
        savingData: {
          ...state.savingData,
          birthday_day: selectFields.birthday_day,
          birthday_month: stateToMonths[selectFields.birthday_month],
          birthday_year: selectFields.birthday_year,
        },
      };

    case CITY_LIST_OPEN:
      return { ...state, isCityListOpen: true };

    case CITY_LIST_CLOSE:
      return { ...state, isCityListOpen: false };

    case CITY_NAME_SELECTED:
      return {
        ...state,
        cityName: action.payload.value,
        isCityIdChange: true,
        savingData: { ...state.savingData, city: action.payload.id },
      };

    case OK_DATA_LOAD + SUCCESS:
      const okData = action.payload;
      const bDateOk = okData.birthday.split('-');

      return {
        ...state,
        cityName: cutCity(okData.location.city),
        savingData: {
          ...state.savingData,
          birthday_day: (bDateOk[2].charAt(0) === '0') ? bDateOk[2].charAt(1) : bDateOk[2],
          birthday_month: (bDateOk[1].charAt(0) === '0') ? bDateOk[1].charAt(1) : bDateOk[1],
          birthday_year: bDateOk[0],
        },
      };

    case VK_DATA_LOAD + SUCCESS:
      const vkData = action.payload;
      const bDateVk = vkData.bdate.split('.');

      return {
        ...state,
        cityName: vkData.city ? vkData.city.title : vkData.home_town,
        savingData: {
          ...state.savingData,
          birthday_day: bDateVk[0],
          birthday_month: bDateVk[1],
          birthday_year: bDateVk[2],
        },
      };

    case CHANGE_ACTIVE_CATEGORY:
      const currentCategory = action.payload;

      return { ...state, activeCategory: (state.activeCategory === currentCategory) ? null : currentCategory };

    case SAVE_SETTINGS + SUCCESS:
      return { ...state, isFormChange: false, activeCategory: null };

    case SAVE_SETTINGS + FAIL:
      return { ...state, isError: true };

    case RESET_PROFILE_ERROR:
      return {
        isEditLoad: false,
        isViewLoad: false,
        isEditableFieldsLoad: false,
        isCityListLoaded: false,
        edit: {},
        view: {},
        editableFields: {},
        cityList: {},
        cityName: '',
        isFormChange: false,
        isCityListOpen: false,
        isCityIdChange: true,
        activeCategory: null,
        savingData: {
          birthday_day: 1,
          birthday_month: 1,
          birthday_year: 1990,
        },
        isError: false,
      };

    default:
      return state;
  }
};
