import {
  SUCCESS,
  FAIL,
  HELP_SEND,
  START_HELP_LOAD,
  GET_CAPTCHA,
  HELP_INPUT_CHANGE,
  NOT_VALID_NUMBER,
} from '../constants';

const initialState = {
  isHelpLoad: true,
  captchaImg: '',
  name: '',
  email: '',
  phone: '',
  msg: '',
  captcha: '',
  errors: [],
  message: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case HELP_SEND + SUCCESS:
      const { errors, msg } = action.payload;

      if (errors.length !== 0 && msg === 'Not valid captcha') {
        return {
          ...state,
          message: 'help_error_captcha',
          isHelpLoad: true,
          captcha: '',
        };
      }

      return {
        ...state,
        isHelpLoad: true,
      };

    case HELP_SEND + FAIL:
      return {
        ...state,
        isHelpLoad: true,
      };

    case START_HELP_LOAD:
      return {
        ...state,
        isHelpLoad: false,
      };

    case GET_CAPTCHA + SUCCESS:
      return {
        ...state,
        captchaImg: action.payload.msg,
        isHelpLoad: true,
      };

    case GET_CAPTCHA + FAIL:
      return {
        ...state,
        isHelpLoad: true,
      };

    case NOT_VALID_NUMBER:
      return {
        ...state,
        message: 'help_error_number',
        captcha: '',
      };

    case HELP_INPUT_CHANGE:
      const { value, fieldName } = action.payload;

      return { ...state, [fieldName]: value, message: '' };

    default:
      return state;
  }
};
