import Axios from 'axios';
import qs from 'qs';
import {
  SUCCESS,
  FAIL,
  LOAD_OWN_EDIT,
  LOAD_OWN_VIEW,
  LOAD_EDITABLE_FIELDS,
  LOAD_CITY_LIST,
  CITY_CHANGE,
  NAME_CHANGE,
  ABOUT_CHANGE,
  CITY_LIST_OPEN,
  CITY_LIST_CLOSE,
  CITY_NAME_SELECTED,
  CHANGE_ACTIVE_CATEGORY,
  SAVE_SETTINGS,
  SELECT_CHANGE,
  SETTINGS_FORM_CHANGE,
  CATEGORIES_ENG,
  RESET_PROFILE_ERROR,
  START_PROFILE_LOAD,
} from '../constants';
import { api } from '../util';

// export function loadOwnEdit() {
//   return (dispatch) => {
//     const url = `${api.ownEdit}`;
//
//     loadInfo(url, dispatch, {
//       onSuccess: LOAD_OWN_EDIT + SUCCESS,
//       onError: LOAD_OWN_EDIT + FAIL,
//     });
//   };
// }
//
// export function loadOwnView() {
//   return (dispatch) => {
//     const url = `${api.ownView}`;
//
//     loadInfo(url, dispatch, {
//       onSuccess: LOAD_OWN_VIEW + SUCCESS,
//       onError: LOAD_OWN_VIEW + FAIL,
//     });
//   };
// }
//
// export function loadEditableFields() {
//   return (dispatch) => {
//     const url = `${api.editableFields}`;
//
//     loadInfo(url, dispatch, {
//       onSuccess: LOAD_EDITABLE_FIELDS + SUCCESS,
//       onError: LOAD_EDITABLE_FIELDS + FAIL,
//     });
//   };
// }


// пришлось делать синхронно 4 начальных запроса на странице профиля, так как в асинхроне
// часто прихдила ошибка с сервера о том что пользователь не авторизован, при последовательных
// запросах нет таких проблем при обмене куками с сервером
export function loadProfileInfo() {
  return (dispatch, getState) => {
    dispatch({
      type: START_PROFILE_LOAD,
    });

    Axios.get(api.ownEdit, { withCredentials: true })
      .then((res) => {
        if (res.status >= 400) throw new Error(res);
        return res.data;
      })
      .then((data) => {
        dispatch({
          type: LOAD_OWN_EDIT + SUCCESS,
          payload: data,
        });
      })
      .catch((error) => {
        console.error('---', error);
        dispatch({
          type: LOAD_OWN_EDIT + FAIL,
          payload: { error },
        });
      })
      .then(() => {
        Axios.get(api.ownView, { withCredentials: true })
          .then((res) => {
            if (res.status >= 400) throw new Error(res);
            return res.data;
          })
          .then((data) => {
            dispatch({
              type: LOAD_OWN_VIEW + SUCCESS,
              payload: data,
            });
          })
          .catch((error) => {
            console.error('---', error);
            dispatch({
              type: LOAD_OWN_VIEW + FAIL,
              payload: { error },
            });
          })
          .then(() => {
            Axios.get(api.editableFields, { withCredentials: true })
              .then((res) => {
                if (res.status >= 400) throw new Error(res);
                return res.data;
              })
              .then((data) => {
                dispatch({
                  type: LOAD_EDITABLE_FIELDS + SUCCESS,
                  payload: data,
                });
              })
              .catch((error) => {
                console.error('---', error);
                dispatch({
                  type: LOAD_EDITABLE_FIELDS + FAIL,
                  payload: { error },
                });
              })
              .then(() => {
                const { profile: { cityName } } = getState();

                Axios
                  .post(api.cityList, qs.stringify({
                    q: cityName,
                  }), { withCredentials: true })
                  .then(res => res.data)
                  .then((data) => {
                    dispatch({
                      type: LOAD_CITY_LIST + SUCCESS,
                      payload: data,
                    });
                  })
                  .catch((err) => {
                    console.error('---', err);
                    dispatch({
                      type: LOAD_CITY_LIST + FAIL,
                      payload: err,
                    });
                  });
              });
          });
      });
  };
}

export function loadCityList() {
  return (dispatch, getState) => {
    const { profile: { cityName } } = getState();

    if (!cityName) return;

    Axios
      .post(api.cityList, qs.stringify({
        q: cityName,
      }), { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: LOAD_CITY_LIST + SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: LOAD_CITY_LIST + FAIL,
          payload: err,
        });
      });
  };
}

export function formChangeHandler() {
  const fields = document.querySelectorAll('.form__checkbox-container');
  const fieldsObj = {};

  for (let i = 0; i < fields.length; i++) {
    const currentField = fields[i];
    const currentLabels = currentField.querySelectorAll('.form__label');
    const currentArr = [];

    for (let j = 0; j < currentLabels.length; j++) {
      const currentLabel = currentLabels[j];
      const currentCheckbox = currentLabel.querySelector('.form__checkbox');

      if (currentCheckbox.checked === true) {
        currentArr.push(currentCheckbox.name);
      } else {
        currentArr.push('0');
      }

      fieldsObj[CATEGORIES_ENG[i]] = currentArr;
    }
  }

  return ({
    type: SETTINGS_FORM_CHANGE,
    payload: fieldsObj,
  });
}

export function cityChange(value, id) {
  return (dispatch, getState) => {
    const { profile: { savingData: { city } } } = getState();

    // проверяем поменялось ли ID города в объекте для отправки на сервер, т.к оно меняется только при
    // выборе города из списка
    if (city === id || city === undefined) {
      dispatch({
        type: CITY_CHANGE + FAIL,
        payload: {
          value,
          id,
        },
      });
    }

    dispatch({
      type: CITY_CHANGE + SUCCESS,
      payload: {
        value,
        id,
      },
    });
  };
}

export function nameChange(evt) {
  return ({
    type: NAME_CHANGE,
    payload: evt.target.value,
  });
}

export function aboutChange(evt) {
  return ({
    type: ABOUT_CHANGE,
    payload: evt.target.value,
  });
}

export function selectChange() {
  const fields = document.querySelectorAll('.form__select');

  const fieldsObj = {};

  for (let i = 0; i < fields.length; i++) {
    const currentField = fields[i];
    fieldsObj[currentField.dataset.name] = currentField.value;
  }

  return ({
    type: SELECT_CHANGE,
    payload: fieldsObj,
  });
}

export function openCityList() {
  return ({
    type: CITY_LIST_OPEN,
  });
}

export function closeCityList() {
  return ({
    type: CITY_LIST_CLOSE,
  });
}

export function cityNameSelect(value, id) {
  return ({
    type: CITY_NAME_SELECTED,
    payload: {
      value,
      id,
    },
  });
}

export function changeActiveCategory(currentCategory) {
  return ({
    type: CHANGE_ACTIVE_CATEGORY,
    payload: currentCategory,
  });
}

export function submitSettings() {
  return (dispatch, getState) => {
    const { profile: { savingData } } = getState();
    const url = `${api.dataSave}`;

    Axios
      .post(url, qs.stringify({
        DatingUserFormData: savingData,
      }), { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: SAVE_SETTINGS + SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: SAVE_SETTINGS + FAIL,
          payload: err,
        });
      });
    this.loadProfileInfo();
  };
}

export function resetProfileError() {
  return {
    type: RESET_PROFILE_ERROR,
  };
}
