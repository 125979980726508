import i18next from 'i18next/index';
import {
  CHANGE_LANGUAGE,
} from '../constants';

export default function changeLanguage() {
  const language = 'ru';

  i18next.init({
    lng: language,
    resources: require(`../dictionaries/${language}.json`),
  });

  return (dispatch) => {
    dispatch({
      type: CHANGE_LANGUAGE,
      payload: {
        language: i18next,
      },
    });
  };
}
