import Axios from 'axios/index';
import qs from 'qs';

import {
  SUCCESS,
  FAIL,
  LOAD_USERS,
  GET_NEXT_USER,
  GET_PREV_USER,
  IMG_LOADED,
  ADD_BLACK_LIST,
  SET_USER_FROM_SLIDER,
  INCREASE_PAGE,
  REMOVE_USER_FROM_LIST,
  SEND_LIKE,
  OPEN_USER_PROFILE_POPUP,
  CLOSE_USER_PROFILE_POPUP,
  GET_USER_CURRENT_PHOTO,
} from '../constants';

import { loadInfo, api } from '../util';
import { submitSearch, submitExtSearch } from './search';

export function loadUserList(isFromSlider) {
  return (dispatch, getState) => {
    if (isFromSlider) {
      dispatch({
        type: INCREASE_PAGE,
      });
    }

    const { userList: { isLoad, page } } = getState();

    if (isLoad && !isFromSlider) return;

    const url = `${api.users}=${page}`;

    loadInfo(url, dispatch, {
      onSuccess: LOAD_USERS + SUCCESS,
      onError: LOAD_USERS + FAIL,
    });
  };
}

export function refreshUser() {
  return (dispatch, getState) => {
    const { userList: { index, users } } = getState();
    const { search: { isSearch, isExtSearch } } = getState();

    if (!users[index + 1]) return;

    dispatch({
      type: GET_NEXT_USER,
      payload: {},
    });

    if (isSearch) {
      dispatch(submitSearch(false));
      return;
    }

    if (isExtSearch) {
      dispatch(submitExtSearch(false));
      return;
    }

    dispatch(loadUserList(false));
  };
}

export function getPrevUser() {
  return (dispatch) => {
    dispatch({
      type: GET_PREV_USER,
    });
  };
}

export function getNextUser() {
  return (dispatch, getState) => {
    const { userList: { index, users } } = getState();
    const { auth: { isLoggedIn } } = getState();
    const { nick } = users[index];
    const { search: { isSearch, isExtSearch } } = getState();
    const isRemove = true;

    if (!isLoggedIn) {
      dispatch({
        type: REMOVE_USER_FROM_LIST,
        payload: { index, users },
      });
      return;
    }

    Axios
      .post(`${api.addBlackList}${nick}`, qs.stringify({
        nick,
      }), { withCredentials: true })
      .then(res => res.data)
      .then(() => {
        dispatch({
          type: REMOVE_USER_FROM_LIST,
          payload: { index, users },
        });
        dispatch({
          type: GET_NEXT_USER,
          payload: {
            isRemove,
          },
        });
      })
      .then(() => {
        if (isSearch) {
          dispatch(submitSearch(false));
          return;
        }
        if (isExtSearch) {
          dispatch(submitExtSearch(false));
          return;
        }
        dispatch(loadUserList(false));
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: ADD_BLACK_LIST + FAIL,
          payload: err,
        });
      });
  };
}

export function setUserFromSlider(index) {
  return (dispatch) => {
    dispatch({
      type: SET_USER_FROM_SLIDER,
      payload: index,
    });
  };
}

export function imgLoadHandler() {
  return ({
    type: IMG_LOADED,
  });
}

export function openUserProfilePopup() {
  return ({
    type: OPEN_USER_PROFILE_POPUP,
  });
}

export function closeUserProfilePopup() {
  return ({
    type: CLOSE_USER_PROFILE_POPUP,
  });
}

export function sendLike() {
  return (dispatch, getState) => {
    const { userList: { users, index } } = getState();

    const currentUserId = users[index].nick;
    const currentPhotoId = users[index].photo_id;

    dispatch(refreshUser());

    Axios
      .post(`${api.sendLike}nick=${currentUserId}&photo_id=${currentPhotoId}`, qs.stringify({
        nick: currentUserId,
        photo_id: currentPhotoId,
      }), { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: SEND_LIKE + SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: SEND_LIKE + FAIL,
          payload: err,
        });
      });
  };
}

export function getUserCurrentPhoto(activePhoto) {
  return (dispatch) => {
    dispatch({
      type: GET_USER_CURRENT_PHOTO,
      payload: activePhoto,
    });
  };
}
