import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
// import { Link } from 'react-router-dom';
// import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';

import { BASE_NAME } from '../../constants';
import Loader from '../Loader/Loader';
import { api } from '../../util';

import './Authentication.scss';

import {
  login,
  inputChange,
  clearAuth,
} from '../../actions/auth';

class Auth extends Component {
  static propTypes = {
    auth: PropTypes.shape({
      message: PropTypes.string,
      isLoggedIn: PropTypes.bool,
    }).isRequired,

    login: PropTypes.func,
    // inputChange: PropTypes.func,

    location: PropTypes.shape({
      state: PropTypes.shape({
        referrer: PropTypes.bool,
      }),
    }),
  };

  state = {
    loading: false,
  };

  componentDidMount() {
    this.props.clearAuth();
  }

  handleSubmit = (evt) => {
    evt.preventDefault();

    this.setState({ loading: true });
    this.props.login();
    this.setState({ loading: false });
  };

  render() {
    document.location.href = api.authUrl;

    const { isLoggedIn } = this.props.auth;
    const { loading } = this.state;
    this.isFromReg = false;

    if (this.props.location.state !== undefined) {
      this.isFromReg = this.props.location.state.referrer;
    }

    if (loading) return <Loader />;
    if (isLoggedIn) {
      // проблема в том, что после логина или логаута необходимо перезагрузить список пользователей,
      // т.к. они отличаются для авторизованного и неавторизованного, вариант без
      // перезагрузки страницы -
      // поменять state: isLoad на false, но что-то пошло не так.
      window.location = BASE_NAME;
    }

    // if (notHaveSub) return <Redirect to="subscription" />;

    return <div />;
    // return (
    //   <div className="auth__container">
    //     {!this.isFromReg && (
    //       <form className="auth-form" method="post" action={api.login} onSubmit={this.handleSubmit}>
    //
    //         <div className="auth-form__fieldset">
    //           <p className="auth-form__greet">
    //             Добро пожаловать в обновленный сервис Знакомства от МТС!
    //           </p>
    //           <Link to="/registration" className="auth-form__button button button--red">
    //             Регистрация
    //           </Link>
    //           <p className="auth-form__greet">
    //             Если вы уже были пользователем Знакомств от МТС, просто введите свой номер телефона:
    //           </p>
    //           <label className="auth-form__label" htmlFor="phone" />
    //           <div className="auth-form__phonebox">
    //             <InputMask
    //               pattern="^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$"
    //               onChange={this.props.inputChange}
    //               className="auth-form__input"
    //               data-name="phone"
    //               mask="(375) 999999999"
    //               placeholder="(375) XXXXXXXXX"
    //               maskChar=""
    //               id="phone"
    //             />
    //           </div>
    //           <label className="auth-form__label" htmlFor="pass">
    //             и пароль:
    //           </label>
    //           <input
    //             type="password"
    //             id="pass"
    //             className="auth-form__input auth-form__input--pass"
    //             data-name="pass"
    //             onChange={this.props.inputChange}
    //           />
    //           <button type="submit" className="auth-form__button button button--red">Отправить</button>
    //           <Link to="/recovery" className="auth-form__button button button--red">
    //             Забыли пароль?
    //           </Link>
    //           <p className="auth-form__error-msg">{message}</p>
    //         </div>
    //       </form>
    //     )}
    //
    //     {this.isFromReg && (
    //       <form className="auth-form" method="post" action={api.login} onSubmit={this.handleSubmit}>
    //         <div className="auth-form__fieldset">
    //           <p className="auth-form__greet auth-form__greet--margin">
    //             У вас уже есть анкета в знакомствах МТС.
    //           </p>
    //           <p className="auth-form__greet auth-form__greet--margin">
    //             Для входа введите пароль, полученный ранее:
    //           </p>
    //           <input
    //             type="password"
    //             id="pass"
    //             className="auth-form__input auth-form__input--pass"
    //             data-name="pass"
    //             onChange={this.props.inputChange}
    //             placeholder="Введите пароль"
    //           />
    //           <button type="submit" className="auth-form__button button button--red">Отправить</button>
    //           <Link to="/recovery" className="auth-form__button button button--red">
    //             Забыли пароль?
    //           </Link>
    //         </div>
    //
    //         {message && <p>{message}</p>}
    //       </form>
    //     )}
    //   </div>
    // );
  }
}

const mapStateToProps = ({ auth }) => ({ auth });

const mapDispatchToProps = {
  login,
  inputChange,
  clearAuth,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));
