import {
  POPUP_CLOSE,
} from '../constants';

function closePopup() {
  return ({
    type: POPUP_CLOSE,
  });
}

export default closePopup;
