import React from 'react';

function AuthIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30">
      <path id="auch-icon" fill="#cacaca" d="M6.052,2.843A15,15,0,1,0,24,2.883c-2.507-1.892-3.525-.221-1.376,1.322A13.125,13.125,0,1,1,7.406,4.182C9.55,2.653,8.55.975,6.052,2.843ZM19.8,7.907l-3.864-3.88V20.02a0.938,0.938,0,1,1-1.875,0V4.026L10.2,7.907a0.935,0.935,0,0,1-1.326,0,0.944,0.944,0,0,1,0-1.332l5.3-5.326A0.918,0.918,0,0,1,15,1a0.918,0.918,0,0,1,.824.244l5.3,5.326a0.945,0.945,0,0,1,0,1.332A0.935,0.935,0,0,1,19.8,7.907Z" transform="" />
    </svg>
  );
}

export default AuthIcon;
