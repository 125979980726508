import React, { Component } from 'react';
import loader from '../../images/loader.svg';

import './Loader.scss';

class Loader extends Component {
  render() {
    return (
      <div className="loader">
        <img className="loader__img" src={loader} alt="loader" />
      </div>
    );
  }
}

export default Loader;
