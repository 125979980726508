import Axios from 'axios/index';
import qs from 'qs';
import {
  CLOSE_SEARCH,
  SUCCESS,
  FAIL,
  OPEN_SEARCH,
  AGE_SELECT_CHANGE,
  START_SEARCH_DATA_LOAD,
  SEARCH_DATA_SEND,
  SEX_SEARCH_CHANGE,
  GEO_SEARCH_CHANGE,
  FIRST_SEARCH_DATA_SEND,
  EXT_SEARCH_DATA_SEND,
  INCREASE_PAGE,
  RESET_SEARCH,
  FIRST_EXT_SEARCH_DATA_SEND,
  extDescMapForRequest,
} from '../constants';
import { api } from '../util';

export function openSearch() {
  return {
    type: OPEN_SEARCH,
  };
}

export function closeSearch() {
  return {
    type: CLOSE_SEARCH,
  };
}

export function selectChange() {
  const fields = document.querySelectorAll('.search-form__select');

  const fieldsObj = {};

  for (let i = 0; i < fields.length; i++) {
    const currentField = fields[i];
    fieldsObj[currentField.dataset.name] = currentField.value;
  }

  return ({
    type: AGE_SELECT_CHANGE,
    payload: fieldsObj,
  });
}

export function changeSex(evt) {
  return (dispatch) => {
    dispatch({
      type: SEX_SEARCH_CHANGE,
      payload: evt.target.value,
    });
  };
}

export function changeGeo(evt) {
  return (dispatch) => {
    dispatch({
      type: GEO_SEARCH_CHANGE,
      payload: evt.target.value,
    });
  };
}

export function submitFirstSearch() {
  return (dispatch, getState) => {
    const { search: { searchData } } = getState();
    const page = 0;

    dispatch({
      type: START_SEARCH_DATA_LOAD,
    });

    Axios
      .post(api.search,
        qs.stringify({
          DatingSearchModel: searchData,
          page,
        }), { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: FIRST_SEARCH_DATA_SEND,
        });
        dispatch({
          type: SEARCH_DATA_SEND + SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: SEARCH_DATA_SEND + FAIL,
          payload: err,
        });
      });
  };
}

export function submitSearch(isFromSlider) {
  return (dispatch, getState) => {
    if (isFromSlider) {
      dispatch({
        type: INCREASE_PAGE,
      });
    }

    const { search: { searchData } } = getState();
    const { userList: { isLoad, page } } = getState();

    if (isLoad && !isFromSlider) return;

    dispatch({
      type: START_SEARCH_DATA_LOAD,
    });

    Axios
      .post(api.search,
        qs.stringify({
          DatingSearchModel: searchData,
          page,
        }), { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: SEARCH_DATA_SEND + SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: SEARCH_DATA_SEND + FAIL,
          payload: err,
        });
      });
  };
}

export function submitFirstExtSearch(searchType) {
  return (dispatch) => {
    const page = 0;

    dispatch({
      type: START_SEARCH_DATA_LOAD,
    });

    Axios
      .get(`${api.compatibleSearch}?page=${page}&type=${extDescMapForRequest[searchType]}`, { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: FIRST_EXT_SEARCH_DATA_SEND,
          payload: searchType,
        });
        dispatch({
          type: EXT_SEARCH_DATA_SEND + SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: EXT_SEARCH_DATA_SEND + FAIL,
          payload: err,
        });
      });
  };
}

export function submitExtSearch(isFromSlider) {
  return (dispatch, getState) => {
    if (isFromSlider) {
      dispatch({
        type: INCREASE_PAGE,
      });
    }

    const { userList: { isLoad, page } } = getState();
    const { search: { extSearchType } } = getState();

    if (isLoad && !isFromSlider) return;

    dispatch({
      type: START_SEARCH_DATA_LOAD,
    });

    Axios
      .get(`${api.compatibleSearch}?page=${page}&type=${extDescMapForRequest[extSearchType]}`, { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: EXT_SEARCH_DATA_SEND + SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: EXT_SEARCH_DATA_SEND + FAIL,
          payload: err,
        });
      });
  };
}

export function resetSearch() {
  return {
    type: RESET_SEARCH,
  };
}
