import React from 'react';

function CloseIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.688 40.688" className="popup__close-icon">
      <path
        fill="#c6c6c6"
        d="M1425.86,5237.14a20,20,0,1,0,0-28.28A20.014,20.014,0,0,0,1425.86,5237.14Zm26.51-26.51a17.494,17.494,0,1,1-24.74,0A17.5,17.5,0,0,1,1452.37,5210.63Zm-7.07,19.44,1.77-1.77-5.3-5.3,5.3-5.3-1.77-1.77-5.3,5.3-5.3-5.3-1.77,1.77,5.3,5.3-5.3,5.3,1.77,1.77,5.3-5.3Z"
        transform="translate(-1420.03 -5203.03)"
      />
    </svg>
  );
}

export default CloseIcon;
