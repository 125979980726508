import {
  OFERTA_START_LOAD,
  OFERTA_LOAD,
  SUCCESS,
  FAIL,
} from '../constants';

const initialState = {
  isOfertaLoad: true,
  oferta: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OFERTA_START_LOAD:
      return {
        ...state,
        isOfertaLoad: false,
      };

    case OFERTA_LOAD + SUCCESS:
      return {
        ...state,
        isOfertaLoad: true,
        oferta: action.payload,
      };

    case OFERTA_LOAD + FAIL:
      return {
        ...state,
        isOfertaLoad: true,
      };

    default:
      return state;
  }
};
