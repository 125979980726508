import React from 'react';

function HelpIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 10 40">
      <path id="help-icon" fill="#cacaca" d="M2315,2633a5,5,0,1,1-5-5A5,5,0,0,1,2315,2633Zm-5-3.25a3.25,3.25,0,1,0,3.25,3.25A3.25,3.25,0,0,0,2310,2629.75Zm5,38.25h-10v-20h10v20Zm-2-18h-6v16h6v-16Z" transform="translate(-2305 -2628)" />
    </svg>
  );
}

export default HelpIcon;
