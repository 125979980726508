import {
  POPUP_OPEN,
  POPUP_CLOSE,
  POPUP_BLACK_LIST,
  POPUP_DELETE_PROFILE,
  POPUP_SUCCESS_DELETE,
  POPUP_SUBSCRIBE,
} from '../constants';

const initialState = {
  isPopupOpen: false,
  isBlackList: false,
  isDeleteProfile: false,
  isSuccessDelete: false,
  isSubscribe: false,
  popupTitle: '',
  popupMessage: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case POPUP_OPEN:
      return {
        ...state,
        isPopupOpen: true,
        popupTitle: action.payload.popupTitle,
        popupMessage: action.payload.popupMessage,
      };

    case POPUP_BLACK_LIST:
      return {
        ...state,
        isBlackList: true,
      };

    case POPUP_DELETE_PROFILE:
      return {
        ...state,
        isDeleteProfile: true,
      };

    case POPUP_SUCCESS_DELETE:
      return {
        ...state,
        isSuccessDelete: true,
      };

    case POPUP_SUBSCRIBE:
      return {
        ...state,
        isSubscribe: true,
      };

    case POPUP_CLOSE:
      return {
        ...state,
        isPopupOpen: false,
        isBlackList: false,
        isDeleteProfile: false,
        isSuccessDelete: false,
        isSubscribe: false,
        popupTitle: '',
        popupMessage: '',
      };

    default:
      return state;
  }
};
