import _ from 'lodash';

import getRandomWave from '../util/getRandomWave';

import {
  SUCCESS,
  FAIL,
  START_CONVERSATION_LOAD,
  LOAD_CONVERSATION_VIEW,
  SET_CONVERSATION_USER_ID,
  RESET_CONVERSATION_PAGE,
  LOAD_MORE_MESSAGES,
  START_LOAD_MESSAGES,
  CONVERSATION_INPUT_CHANGE,
  START_SEND_MESSAGE,
  SEND_MESSAGE,
  ADD_EMOJI,
  CHANGE_EMOJI_FIELD_STATE,
  RESET_CONVERSATION_PAGE_LANGUAGE,
  START_RECORDING,
  STOP_RECORDING,
  SET_TIMEOUT_ID,
  SCREEN_LOGGER,
  FOCUS_INPUT,
  BLUR_INPUT,
  SET_CARET_POSITION,
  OPEN_STREAM,
  GET_MESSAGE_FROM_STREAM,
  SET_LOCATION_TYPE,
  SET_PHOTO_TYPE,
  SET_TEXT_TYPE,
  SET_LOCATION_COORDS,
  LOAD_MESSAGES_AFTER_SEND,
  ADD_CHAT_PHOTO,
  REMOVE_CHAT_PHOTO,
  SCROLL_TO_BOTTOM,
  CANCEL_SCROLL_TO_BOTTOM,
  REMOVE_VOICE,
} from '../constants';

const initialState = {
  data: null,
  conversationView: {},
  isMessages: true,
  fullMessages: [],
  message: '',
  isMessageSend: true,
  isEmojiFieldOpen: false,
  currentPage: 1,
  currentUserId: '',
  currentPhotoId: '',
  isConversationLoad: false,
  isMoreMessagesLoad: true,
  hideLoadButton: false,
  ErrorMessage: '',
  isError: false,
  isScroll: true,
  isRecording: false,
  audioData: null,
  timeoutId: null,
  logmsg: '',
  caretPos: 0,
  isMessage: false,
  msgType: 'text',
  coords: {
    lat: 55.754965,
    lon: 37.617677,
  },
  photoToLoad: null,
  isStream: false,
};

function getMessagesWithVoiceWaves(currentMessages) {
  return _.map(currentMessages, (option) => {
    if (option.type === 'voice') {
      option.wave = getRandomWave();
    }
    return option;
  });
}

function addMessagesToList(fullMessages, currentMessages) {
  _.map(currentMessages, (option) => {
    if (fullMessages.findIndex(el => (el.id === option.id)) === -1) {
      fullMessages.unshift(option)
    }
  });

  return fullMessages;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case START_CONVERSATION_LOAD:
      return {
        ...state,
        isConversationLoad: false,
      };

    case LOAD_CONVERSATION_VIEW + SUCCESS:
      if (action.payload.messages.lenght === 0) {
        return {
          ...state,
          isMessages: false,
        };
      }

      return {
        ...state,
        isConversationLoad: true,
        conversationView: action.payload.personInfo,
        fullMessages: addMessagesToList(state.fullMessages, getMessagesWithVoiceWaves(action.payload.messages)),
        isMessages: true,
      };

    case LOAD_CONVERSATION_VIEW + FAIL:
      return {
        ...state,
        isConversationLoad: true,
        isError: true,
      };

    case SET_CONVERSATION_USER_ID:
      return {
        ...state,
        currentUserId: action.payload,
      };

    case START_LOAD_MESSAGES:
      return {
        ...state,
        isMoreMessagesLoad: false,
      };

    case LOAD_MORE_MESSAGES + SUCCESS:
      return {
        ...state,
        isMoreMessagesLoad: true,
        fullMessages: addMessagesToList(state.fullMessages, getMessagesWithVoiceWaves(action.payload.data.messages)),
        currentPage: action.payload.currentPage,
        hideLoadButton: !action.payload.data.messages[0],
      };

    case GET_MESSAGE_FROM_STREAM:
      return {
        ...state,
        fullMessages: [ ...state.fullMessages, getMessagesWithVoiceWaves([action.payload])[0] ],
      };

    case LOAD_MORE_MESSAGES + FAIL:
      return {
        ...state,
        isMoreMessagesLoad: true,
        isError: true,
      };

    case CONVERSATION_INPUT_CHANGE:
      return {
        ...state,
        message: action.payload.value.replace(/&nbsp;/g, ' '),
      };

    case START_SEND_MESSAGE:
      return {
        ...state,
        isMessageSend: false,
      };

    case SEND_MESSAGE + SUCCESS:
      if (action.payload.errors.length !== 0) {
        return {
          ...state,
          isMessageSend: true,
          errorMessage: action.payload.errors[0].text,
          msgType: 'text',
          audioData: null,
          photoToLoad: null,
        };
      }

      return {
        ...state,
        isMessageSend: true,
        message: '',
        errorMessage: '',
        isEmojiFieldOpen: false,
        msgType: 'text',
        audioData: null,
        photoToLoad: null,
      };

    case SEND_MESSAGE + FAIL:
      return {
        ...state,
        isMessageSend: true,
        isError: true,
      };

    case ADD_EMOJI:
      return {
        ...state,
        message: action.payload.value.replace(/&nbsp;/g, ' '),
      };

    case CHANGE_EMOJI_FIELD_STATE:
      return {
        ...state,
        isEmojiFieldOpen: action.payload,
      };

        case START_RECORDING:
      return {
        ...state,
        isRecording: true,
        msgType: 'voice',
      };

    case STOP_RECORDING:
      return {
        ...state,
        isRecording: false,
        audioData: action.payload,
      };

    case REMOVE_VOICE:
      return {
        ...state,
        audioData: null,
        msgType: 'text',
      };

    case SET_LOCATION_COORDS:
      return {
        ...state,
        coords: { ...state.coords, lat: action.payload.lat, lon: action.payload.lng }
      };

    case ADD_CHAT_PHOTO:
      return {
        ...state,
        photoToLoad: action.payload
      };

    case ADD_CHAT_PHOTO + FAIL:
      return {
        ...state,
        msgType: 'text',
      };

    case REMOVE_CHAT_PHOTO:
      return {
        ...state,
        photoToLoad: null,
        msgType: 'text',
      };

    case SET_LOCATION_TYPE:
      return {
        ...state,
        msgType: 'location',
      };

    case SET_PHOTO_TYPE:
      return {
        ...state,
        msgType: 'photo',
      };

    case SET_TEXT_TYPE:
      return {
        ...state,
        msgType: 'text',
      };

    case FOCUS_INPUT:
      return {
        ...state,
        isScroll: false,
      };

    case BLUR_INPUT:
      return {
        ...state,
        isScroll: true,
      };

    case SET_CARET_POSITION:
      return {
        ...state,
        caretPos: action.payload,
      };

    case OPEN_STREAM:
      return {
        ...state,
        isStream: true,
      };

    case SCROLL_TO_BOTTOM:
      return {
        ...state,
        isScroll: true,
      };

    case CANCEL_SCROLL_TO_BOTTOM:
      return {
        ...state,
        isScroll: false,
      };

    case RESET_CONVERSATION_PAGE:
      return {
        ...state,
        conversationView: {},
        fullMessages: [],
        isMessages: true,
        currentUserId: '',
        currentPage: 1,
        currentPhotoId: '',
        isEmojiFieldOpen: false,
        isConversationLoad: false,
        isMoreMessagesLoad: true,
        hideLoadButton: false,
        message: '',
        errorMessage: '',
        isError: false,
        isScroll: true,
        isRecording: false,
        audioData: null,
      };

    case RESET_CONVERSATION_PAGE_LANGUAGE:
      return {
        ...state,
        conversationView: {},
        fullMessages: [],
        isMessages: true,
        currentPage: 1,
        currentPhotoId: '',
        isEmojiFieldOpen: false,
        isConversationLoad: false,
        isMoreMessagesLoad: true,
        hideLoadButton: false,
        isError: false,
        isScroll: true,
        isRecording: false,
        audioData: null,
        caretPos: 0,
      };

    default:
      return state;
  }
};
