import _ from 'lodash';

import {
  FAIL,
  SUCCESS,
  START_MESSAGES_LOAD,
  MESSAGES_LOAD,
  RESET_MESSAGES_PAGE,
} from '../constants';

const initialState = {
  isMessagesLoad: true,
  isFirstLoad: false,
  allDialogs: [],
  isError: false,
  messagesCnt: 0,
  currentPage: 0,
};

function addDialogs(allDialogs, currentDialogs) {
  _.map(currentDialogs, option => allDialogs.push(option));

  return allDialogs;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case START_MESSAGES_LOAD:
      return {
        ...state,
        isMessagesLoad: false,
      };

    case MESSAGES_LOAD + SUCCESS:
      return {
        ...state,
        isFirstLoad: true,
        isMessagesLoad: true,
        allDialogs: addDialogs(state.allDialogs, action.payload.messages),
        messagesCnt: action.payload.cnt,
        currentPage: state.currentPage + 1,
      };

    case MESSAGES_LOAD + FAIL:
      return {
        ...state,
        isMessagesLoad: true,
        isError: false,
      };

    case RESET_MESSAGES_PAGE:
      return {
        ...state,
        isMessagesLoad: true,
        isFirstLoad: false,
        allDialogs: [],
        isError: false,
        messagesCnt: 0,
        currentPage: 0,
      };

    default:
      return state;
  }
};
