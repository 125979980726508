import React from 'react';

function SettingsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 40 40">
      <path id="settings-icon" fill="#cacaca" d="M2329.86,2545.68l-0.1-.92-5.35-.93a16.164,16.164,0,0,0-.55-1.57,15.648,15.648,0,0,0-.72-1.49l3.12-4.44-0.57-.73a20.352,20.352,0,0,0-3.28-3.28l-0.73-.58-4.45,3.13a14.781,14.781,0,0,0-3.05-1.27l-0.93-5.35-0.93-.11a20,20,0,0,0-4.64,0l-0.92.11-0.93,5.35a13.875,13.875,0,0,0-1.57.55,15.541,15.541,0,0,0-1.49.71l-4.44-3.12-0.73.58a19.575,19.575,0,0,0-3.28,3.28l-0.58.73,3.13,4.44a14.638,14.638,0,0,0-1.27,3.06l-5.35.93-0.11.92a20,20,0,0,0,0,4.64l0.11,0.93,5.35,0.93a13.714,13.714,0,0,0,.55,1.56,14.633,14.633,0,0,0,.71,1.5l-3.12,4.44,0.58,0.73a19.575,19.575,0,0,0,3.28,3.28l0.73,0.57,4.44-3.12a14.6,14.6,0,0,0,3.06,1.26l0.93,5.36,0.92,0.1a19.293,19.293,0,0,0,4.64,0l0.93-.1,0.93-5.35a15.974,15.974,0,0,0,1.56-.55,14.718,14.718,0,0,0,1.5-.72l4.44,3.12,0.73-.57a20.352,20.352,0,0,0,3.28-3.28l0.57-.73-3.12-4.45a14.746,14.746,0,0,0,1.26-3.05l5.36-.93,0.1-.93A19.465,19.465,0,0,0,2329.86,2545.68Zm-2.39,3.43-5.1.88-0.19.81a12.385,12.385,0,0,1-1.59,3.83l-0.44.71,2.98,4.23a17.667,17.667,0,0,1-1.56,1.56l-4.23-2.98-0.7.44a12.152,12.152,0,0,1-1.86.96,12.371,12.371,0,0,1-1.98.63l-0.81.19-0.88,5.1a20.365,20.365,0,0,1-2.21,0l-0.89-5.1-0.81-.19a12.385,12.385,0,0,1-3.83-1.59l-0.7-.44-4.24,2.98a17.667,17.667,0,0,1-1.56-1.56l2.98-4.23-0.44-.7a13.642,13.642,0,0,1-.96-1.86,12.7,12.7,0,0,1-.63-1.98l-0.18-.81-5.1-.88a16.3,16.3,0,0,1,0-2.21l5.1-.89,0.18-.81a12.631,12.631,0,0,1,1.59-3.83l0.44-.7-2.98-4.23a16.618,16.618,0,0,1,1.57-1.57l4.23,2.98,0.7-.44a12.212,12.212,0,0,1,3.83-1.59l0.81-.19,0.89-5.09a16.3,16.3,0,0,1,2.21,0l0.88,5.1,0.81,0.18a12.631,12.631,0,0,1,3.83,1.59l0.71,0.44,4.23-2.98a17.784,17.784,0,0,1,1.56,1.57l-2.98,4.23,0.44,0.7a12.055,12.055,0,0,1,.96,1.85,13.049,13.049,0,0,1,.63,1.98l0.19,0.81,5.09,0.89A18.1,18.1,0,0,1,2327.47,2549.11Zm-17.47-9.23a8.125,8.125,0,1,0,8.13,8.12A8.126,8.126,0,0,0,2310,2539.88Zm0,13.75a5.625,5.625,0,1,1,5.63-5.63A5.633,5.633,0,0,1,2310,2553.63Z" transform="translate(-2290.03 -2528.03)" />
    </svg>
  );
}

export default SettingsIcon;
