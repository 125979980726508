import {
  AGE_SELECT_CHANGE,
  CLOSE_SEARCH,
  SUCCESS,
  FAIL,
  OPEN_SEARCH,
  SEARCH_DATA_SEND,
  START_SEARCH_DATA_LOAD,
  SEX_SEARCH_CHANGE,
  GEO_SEARCH_CHANGE,
  FIRST_SEARCH_DATA_SEND,
  FIRST_EXT_SEARCH_DATA_SEND,
  EXT_SEARCH_DATA_SEND,
  RESET_SEARCH,
} from '../constants';

const initialState = {
  isSearchOpen: false,
  searchData: {
    age_from: 18,
    age_to: 25,
    sex: 2,
    geo_mode: 1,
  },
  currentPage: 0,
  isSearchLoad: true,
  isError: false,
  isSearch: false,
  isExtSearch: false,
  isFirstDataSend: true,
  extSearchType: '',
  searchCnt: 1,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_SEARCH:
      return {
        ...state,
        isSearchOpen: true,
      };

    case CLOSE_SEARCH:
      return {
        ...state,
        isSearchOpen: false,
      };

    case AGE_SELECT_CHANGE:
      const selectFields = action.payload;

      return {
        ...state,
        searchData: {
          ...state.searchData,
          age_from: selectFields.age_from,
          age_to: selectFields.age_to,
        },
      };

    case SEX_SEARCH_CHANGE:
      return {
        ...state,
        searchData: {
          ...state.searchData,
          sex: action.payload,
        },
      };

    case GEO_SEARCH_CHANGE:
      return {
        ...state,
        searchData: {
          ...state.searchData,
          geo_mode: action.payload,
        },
      };

    case START_SEARCH_DATA_LOAD:
      return {
        ...state,
        isSearchLoad: false,
      };

    case FIRST_SEARCH_DATA_SEND:
      return {
        ...state,
        isFirstDataSend: false,
        isExtSearch: false,
      };

    case SEARCH_DATA_SEND + SUCCESS:
      return {
        ...state,
        isSearchLoad: true,
        isSearch: true,
        isSearchOpen: false,
        searchCnt: action.payload.cnt,
      };

    case SEARCH_DATA_SEND + FAIL:
      return {
        ...state,
        isSearchLoad: true,
        isError: true,
        isSearchOpen: false,
        searchCnt: 0,
      };

    case FIRST_EXT_SEARCH_DATA_SEND:
      return {
        ...state,
        extSearchType: action.payload,
        isFirstDataSend: false,
      };

    case EXT_SEARCH_DATA_SEND + SUCCESS:
      return {
        ...state,
        isSearchLoad: true,
        isExtSearch: true,
        isSearchOpen: false,
        searchCnt: action.payload.cnt,
      };

    case EXT_SEARCH_DATA_SEND + FAIL:
      return {
        ...state,
        isSearchLoad: true,
        isError: true,
        isSearchOpen: false,
        searchCnt: 0,
      };

    case RESET_SEARCH:
      return {
        ...state,
        isSearchOpen: false,
        currentPage: 0,
        isSearchLoad: true,
        isError: false,
        isFirstDataSend: true,
      };

    default:
      return state;
  }
};
