import Axios from 'axios';
import qs from 'qs';
import {
  SUCCESS,
  FAIL,
  START_USER_INFO_LOAD,
  LOAD_USER_VIEW,
  LOAD_USER_PHOTO,
  GET_CURRENT_PHOTO,
  RESET_USER_PAGE,
  IMG_LOADED,
  SEND_LIKE,
  POPUP_OPEN,
  SET_USER_ID,
  POPUP_BLACK_LIST,
  ADD_BLACK_LIST,
  POPUP_CLOSE,
  SET_PHOTOS_FROM_MAIN,
  USER_VIEW_FROM_MAIN_LOAD,
  REMOVE_USER_FROM_LIST,
} from '../constants';
import { api } from '../util';

export function loadUserInfo(uid, fromMain) {
  return (dispatch) => {
    dispatch({
      type: START_USER_INFO_LOAD,
    });

    Axios.get(`${api.userView}${uid}`, { withCredentials: true })
      .then((res) => {
        if (res.status >= 400) throw new Error(res);
        return res.data;
      })
      .then((data) => {
        dispatch({
          type: LOAD_USER_VIEW + SUCCESS,
          payload: data,
        });
        dispatch({
          type: SET_USER_ID,
          payload: uid,
        });
      })
      .catch((error) => {
        console.error('---', error);
        dispatch({
          type: LOAD_USER_VIEW + FAIL,
          payload: { error },
        });
      })
      .then(() => {
        if (!fromMain) {
          Axios.get(`${api.userPhotoList}${uid}`, { withCredentials: true })
            .then((res) => {
              if (res.status >= 400) throw new Error(res);
              return res.data;
            })
            .then((data) => {
              dispatch({
                type: LOAD_USER_PHOTO + SUCCESS,
                payload: data,
              });
            })
            .catch((error) => {
              console.error('---', error);
              dispatch({
                type: LOAD_USER_PHOTO + FAIL,
                payload: { error },
              });
            });
        } else {
          dispatch({
            type: USER_VIEW_FROM_MAIN_LOAD + SUCCESS,
          });
        }
      });
  };
}

export function getCurrentPhoto(activeSlide) {
  return (dispatch) => {
    dispatch({
      type: GET_CURRENT_PHOTO,
      payload: activeSlide,
    });
  };
}

export function resetUserPage() {
  return {
    type: RESET_USER_PAGE,
  };
}

export function imgLoadHandler() {
  return ({
    type: IMG_LOADED,
  });
}

export function setPhotosFromMain(photos, curPhotoIndex) {
  return (dispatch) => {
    dispatch({
      type: SET_PHOTOS_FROM_MAIN,
      payload: { photos, curPhotoIndex }
    });
  };
}

export function sendLike() {
  return (dispatch, getState) => {
    const { user: { currentPhotoId, currentUserId } } = getState();

    // dispatch({
    //   type: START_USER_INFO_LOAD,
    // });

    Axios
      .post(`${api.sendLike}nick=${currentUserId}&photo_id=${currentPhotoId}`, qs.stringify({
        nick: currentUserId,
        photo_id: currentPhotoId,
      }), { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: POPUP_OPEN,
          payload: {
            popupTitle: '',
            popupMessage: 'popup_send_like_message',
          },
        });
        dispatch({
          type: SEND_LIKE + SUCCESS,
          payload: data,
        });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: POPUP_OPEN,
          payload: {
            popupTitle: 'popup_error_title',
            popupMessage: 'popup_error_message',
          },
        });
        dispatch({
          type: SEND_LIKE + FAIL,
          payload: err,
        });
      });
  };
}

export function openBlackListPopup() {
  return (dispatch) => {
    dispatch({
      type: POPUP_OPEN,
      payload: {
        popupTitle: 'popup_add_blacklist_title',
        popupMessage: 'popup_add_blacklist_message',
      },
    });
    dispatch({
      type: POPUP_BLACK_LIST,
    });
  };
}

export function addToBlackList() {
  return (dispatch, getState) => {
    const { user: { currentUserId } } = getState();
    const { userList: { users } } = getState();

    // dispatch({
    //   type: START_USER_INFO_LOAD,
    // });

    Axios
      .post(`${api.addBlackList}${currentUserId}`, qs.stringify({
        nick: currentUserId,
      }), { withCredentials: true })
      .then(res => res.data)
      .then((data) => {
        dispatch({
          type: ADD_BLACK_LIST + SUCCESS,
          payload: data,
        });
        dispatch({
          type: REMOVE_USER_FROM_LIST,
          payload: { users, currentUserId },
        });
        dispatch({
          type: POPUP_CLOSE,
          payload: data,
        });
      })
      .catch((err) => {
        console.error('---', err);
        dispatch({
          type: POPUP_OPEN,
          payload: {
            popupTitle: 'popup_error_title',
            popupMessage: 'popup_error_message',
          },
        });
        dispatch({
          type: ADD_BLACK_LIST + FAIL,
          payload: err,
        });
      });
  };
}
