import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import userList from './userList';
import auth from './auth';
import reg from './reg';
import help from './help';
import profile from './profile';
import photos from './photos';
import popup from './popup';
import messages from './messages';
import user from './user';
import conversation from './conversation';
import settings from './settings';
import search from './search';
import language from './language';
import oferta from './oferta';
import photoEditor from './photoEditor';

export default history => combineReducers({
  router: connectRouter(history),
  popup,
  userList,
  auth,
  reg,
  help,
  profile,
  photos,
  messages,
  user,
  conversation,
  settings,
  search,
  language,
  oferta,
  photoEditor,
});
