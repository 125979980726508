import {
  SUCCESS,
  FAIL,
  START_SETTINGS_LOAD,
  LOAD_SETTINGS_DATA,
  CHANGE_SMS_SETTING,
  SAVE_SETTINGS_DATA,
  DELETE_PROFILE,
  // SAVE_SETTINGS_DATA,
  // DELETE_PROFILE,
} from '../constants';

const initialState = {
  isSettingsLoad: true,
  smsSetting: '',
  isError: false,
  savingData: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case START_SETTINGS_LOAD:
      return {
        ...state,
        isSettingsLoad: false,
      };

    case LOAD_SETTINGS_DATA + SUCCESS:
      return {
        ...state,
        isSettingsLoad: true,
        smsSetting: action.payload.wapSmsTime,
        savingData: {
          ...state.savingData,
          wapSmsTime: action.payload.wapSmsTime,
        },
      };

    case LOAD_SETTINGS_DATA + FAIL:
      return {
        ...state,
        isSettingsLoad: true,
        isError: true,
      };

    case CHANGE_SMS_SETTING:
      return {
        ...state,
        smsSetting: action.payload,
        savingData: {
          ...state.savingData,
          wapSmsTime: action.payload,
        },
      };

    case SAVE_SETTINGS_DATA + SUCCESS:
      return {
        ...state,
        isSettingsLoad: true,
      };

    case SAVE_SETTINGS_DATA + FAIL:
      return {
        ...state,
        isSettingsLoad: true,
        isError: true,
      };

    case DELETE_PROFILE + SUCCESS:
      return {
        ...state,
        isSettingsLoad: true,
      };

    case DELETE_PROFILE + FAIL:
      return {
        ...state,
        isSettingsLoad: true,
        isError: true,
      };

    default:
      return state;
  }
};
