import {
  CHANGE_LANGUAGE,
  START_LOAD_LANGUAGE,
  SUCCESS,
  FAIL,
  START_USER_INFO_LOAD,
  START_PROFILE_LOAD, START_CONVERSATION_LOAD,
} from '../constants';

const initialState = {
  language: '',
  isLanguageLoad: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case START_LOAD_LANGUAGE:
      return { ...state, isLanguageLoad: false };

    case CHANGE_LANGUAGE:
      return { ...state, language: action.payload.language };

    case CHANGE_LANGUAGE + SUCCESS:
      return { ...state, isLanguageLoad: true };

    case CHANGE_LANGUAGE + FAIL:
      return { ...state, isLanguageLoad: true };

    case START_USER_INFO_LOAD:
      return {
        ...state,
        isLanguageLoad: false,
      };

    case START_PROFILE_LOAD:
      return {
        ...state,
        isLanguageLoad: false,
      };

    case START_CONVERSATION_LOAD:
      return {
        ...state,
        isLanguageLoad: false,
      };

    default:
      return state;
  }
};
