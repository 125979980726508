import {
  SUCCESS,
  FAIL,
  AUTH,
  AUTH_INPUT_CHANGE,
  REDIRECT_TO_AUTH,
  LOGOUT,
  EVT_STATUS,
  RESET_EVT_STATUS,
  START_RECOVERY_SEND,
  RECOVERY_SEND_PHONE,
  DEFAULT_SID,
  CLEAR_AUTH,
  START_SUB_SEND,
  SUB_SEND,
} from '../constants';

const initialState = {
  isEventStatusLoad: false,
  isLoginLoad: false,
  isLoggedIn: false,
  notHaveSub: false,
  sid: DEFAULT_SID,
  isRedirect: false,
  notFullReg: true,
  status: '',
  phone: '',
  pass: '',
  message: '',
  nick: '',
  sex: '',
  userStates: [],
  errors: [],
  isAuthRequestLoad: true,
  isPhoneSend: false,
  isSubSend: true,
  unreadCount: '0',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH + SUCCESS: {
      const {
        message, nick, sex, userStates, errors,
      } = action.payload;
      const phone = action.payload.tel;

      if (typeof errors !== 'undefined') {
        return {
          ...state,
          message: errors[0].password || errors[0].login,
          userStates,
          isLoginLoad: true,
          notHaveSub: (userStates.indexOf(107) !== -1 && userStates.indexOf(109) !== -1),
          isLoggedIn: (userStates.indexOf(100) !== -1 && userStates.indexOf(107) !== -1 && userStates.indexOf(108) !== -1),
          notFullReg: (userStates.indexOf(103) !== -1 && userStates.indexOf(107) !== -1),
          isRedirect: (userStates.indexOf(106) !== -1 || userStates.indexOf(109) !== -1),
        };
      }

      return {
        ...state,
        pass: '',
        phone,
        message,
        nick,
        sex,
        userStates,
        notHaveSub: (userStates.indexOf(107) !== -1 && userStates.indexOf(109) !== -1),
        isLoggedIn: (userStates.indexOf(100) !== -1 && userStates.indexOf(107) !== -1 && userStates.indexOf(108) !== -1),
        notFullReg: (userStates.indexOf(103) !== -1 && userStates.indexOf(107) !== -1),
        isRedirect: (userStates.indexOf(106) !== -1 || userStates.indexOf(109) !== -1),
        errors,
        isLoginLoad: true,
      };
    }

    case AUTH + FAIL:
      return { ...state, isLoginLoad: true, message: 'Возникла ошибка, попробуйте ещё раз' };

    case REDIRECT_TO_AUTH:
      return {
        ...state,
        // isRedirect: true,
      };

    case EVT_STATUS + SUCCESS: {
      const { status } = action.payload;
      if (status === 'ERROR') {
        return {
          ...state,
          isEventStatusLoad: true,
        };
      }
      return {
        ...state,
        isEventStatusLoad: true,
        unreadCount: action.payload.newMessageCount,
      };
    }

    case RESET_EVT_STATUS: {
      return {
        ...state,
        isEventStatusLoad: false,
      };
    }

    case LOGOUT + SUCCESS:
      return {
        isEventStatusLoad: true,
        isLoginLoad: true,
      };

    case AUTH_INPUT_CHANGE:
      const { value, fieldName } = action.payload;

      return { ...state, [fieldName]: value };

    case START_RECOVERY_SEND:
      return {
        ...state,
        isAuthRequestLoad: false,
      };

    case RECOVERY_SEND_PHONE + SUCCESS:
      const { errors, msg } = action.payload;

      if (errors.length !== 0 && msg === 'Неверный логин') {
        return {
          ...state,
          message: msg,
          isAuthRequestLoad: true,
        };
      }

      return {
        ...state,
        isAuthRequestLoad: true,
        isPhoneSend: true,
        message: '',
      };

    case RECOVERY_SEND_PHONE + FAIL:
      return {
        ...state,
        message: 'Возникла ошибка, попробуйте ещё раз',
        isAuthRequestLoad: true,
      };

    case START_SUB_SEND:
      return {
        ...state,
        isSubSend: false,
      };

    case SUB_SEND + SUCCESS:
      return {
        ...state,
        isSubSend: true,
        notHaveSub: (action.payload.userStates.indexOf(107) !== -1 && action.payload.userStates.indexOf(109) !== -1),
        isLoggedIn: (action.payload.userStates.indexOf(100) !== -1 && action.payload.userStates.indexOf(107) !== -1 && action.payload.userStates.indexOf(108) !== -1),
        notFullReg: (action.payload.userStates.indexOf(103) !== -1 && action.payload.userStates.indexOf(107) !== -1),
        isRedirect: (action.payload.userStates.indexOf(106) !== -1 || action.payload.userStates.indexOf(109) !== -1),
      };

    case SUB_SEND + FAIL:
      return {
        ...state,
        isSubSend: true,
      };

    case CLEAR_AUTH:
      return {
        ...state,
        message: '',
      };

    default:
      return state;
  }
};
