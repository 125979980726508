import {
  CANCEL_PHOTO_EDIT,
  CANCEL_SECOND_STEP,
  OPEN_PHOTO_EDITOR,
  CLOSE_PHOTO_EDITOR,
  GO_TO_EDIT_SECOND_STEP,
} from '../constants';

const initialState = {
  isPhotoLoad: false,
  isPhotoEditorOpened: false,
  editFirstStep: true,
  editSecondStep: false,
  file: null,
  fileBeforeCrop: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_PHOTO_EDITOR:
      return {
        ...state,
        isPhotoEditorOpened: true,
        editFirstStep: true,
        editSecondStep: false,
        file: action.payload,
        fileBeforeCrop: action.payload,
      };

    case CANCEL_PHOTO_EDIT:
      return {
        ...state,
        isPhotoEditorOpened: false,
        file: null,
      };

    case CANCEL_SECOND_STEP:
      return {
        ...state,
        editFirstStep: true,
        editSecondStep: false,
        file: state.fileBeforeCrop,
      };

    case GO_TO_EDIT_SECOND_STEP:
      return {
        ...state,
        editFirstStep: false,
        editSecondStep: true,
        file: action.payload,
      };

    case CLOSE_PHOTO_EDITOR:
      return {
        ...state,
        isPhotoLoad: true,
        isPhotoEditorOpened: false,
        file: null,
      };

    default:
      return state;
  }
};
