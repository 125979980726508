import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import Cookies from 'js-cookie';

import Header from './components/Header/Header';
import Auth from './components/Authentication/Authentication';
import Loader from './components/Loader/Loader';
import Sub from './components/Sub/Sub';

// import api from './util/api';

import './App.scss';

import { checkEvtStatus, resetEvtStatus, login } from './actions/auth';
import changeLanguage from './actions/language';
import setLanguage from './util/setLanguage';
import { openStream } from './actions/conversation';

const Messages = loadable(() => import('./components/Messages/Messages'));
const Conversation = loadable(() => import('./components/Conversation/Conversation'));
const Main = loadable(() => import('./components/Main/Main'));
const Reg = loadable(() => import('./components/Reg/Reg'));
const Recovery = loadable(() => import('./components/Recovery/Recovery'));
const Help = loadable(() => import('./components/Help/Help'));
const Oferta = loadable(() => import('./components/Oferta/Oferta'));
const Settings = loadable(() => import('./components/Settings/Settings'));
const Profile = loadable(() => import('./components/Profile/Profile'));
const Photos = loadable(() => import('./components/Photos/Photos'));
const User = loadable(() => import('./components/User/User'));
const ExtSearch = loadable(() => import('./components/ExtSearch/ExtSearch'));

class App extends Component {
  static propTypes = {
    history: PropTypes.shape({
      listen: PropTypes.func,
    }),

    resetEvtStatus: PropTypes.func,
    checkEvtStatus: PropTypes.func,

    location: PropTypes.shape({
      pathname: PropTypes.string,
      split: PropTypes.func,
    }),

    auth: PropTypes.shape({
      isEventStatusLoad: PropTypes.bool,
    }),
  };

  componentDidMount() {
    this.props.login();
    this.props.checkEvtStatus();
    this.props.changeLanguage();
    this.props.openStream();
  }

  componentWillUnmount() {
    this.props.resetEvtStatus();
  }

  getTitle = () => {
    const { language } = this.props.language;

    switch (this.props.location.pathname.split('/')[1]) {
      case 'authentication':
        return 'Авторизация';
      case 'registration':
        return setLanguage(language, 'reg_title');
      case 'recovery':
        return 'Восстановление пароля';
      case 'help':
        return setLanguage(language, 'help_title');
      case 'oferta':
        return setLanguage(language, 'oferta_title');
      case 'settings':
        return setLanguage(language, 'settings_title');
      case 'profile':
        return setLanguage(language, 'profile_title');
      case 'photos':
        return setLanguage(language, 'profile_title');
      case 'messages':
        return setLanguage(language, 'messages_title');
      case 'user':
        return 'Страница пользователя';
      case 'conversation':
        return 'Диалог';
      case 'subscription':
        return 'Подписка';
      case 'extsearch':
        return 'Подбор по совместимости';
      default:
        return setLanguage(language, 'index_title');
    }
  };

  getHeaderTitle = () => {
    const { language } = this.props.language;

    switch (this.props.location.pathname.split('/')[1]) {
      case 'authentication':
        return 'Авторизация';
      case 'registration':
        return setLanguage(language, 'reg_title_v2');
      case 'recovery':
        return 'Восстановление пароля';
      case 'help':
        return setLanguage(language, 'help_title_v2');
      case 'oferta':
        return setLanguage(language, 'oferta_title_v2');
      case 'settings':
        return setLanguage(language, 'settings_title_v2');
      case 'profile':
        return setLanguage(language, 'profile_title_v2');
      case 'photos':
        return setLanguage(language, 'gallery_title_v2');
      case 'messages':
        return setLanguage(language, 'messages_title_v2');
      case 'user':
        return 'Страница пользователя';
      case 'conversation':
        return 'Диалог';
      case 'subscription':
        return 'Подписка 18+';
      case 'extsearch':
        return 'Совместимость';
      default:
        return setLanguage(language, 'index_title_v2');
    }
  };

  render() {
    const title = this.getTitle();
    const headerTitle = this.getHeaderTitle();

    const {
      isEventStatusLoad, isLoginLoad,
    } = this.props.auth;

    if (!isEventStatusLoad || !isLoginLoad) return <Loader />;

    // if (notHaveSub) window.location.href = api.authUrl;

    return (
      <div className="wrapper">
        {/*<div className="necessary-width">*/}
          {/*<div className="ext-wrapper">*/}
            <Helmet>
              <title>{title}</title>
            </Helmet>

            <Header title={headerTitle} />

            <Switch>
              <Route path="/subscription" component={Sub} />
              <Route path="/authentication" component={Auth} />
              <Route path="/extsearch" render={() => <ExtSearch />} />
              <Route path="/registration" render={() => <Reg />} />
              <Route path="/recovery" render={() => <Recovery />} />
              <Route path="/help" render={() => <Help />} />
              <Route path="/oferta" render={() => <Oferta />} />
              <Route path="/settings" render={() => <Settings />} />
              <Route path="/profile" render={() => <Profile />} />
              <Route path="/photos" render={() => <Photos />} />
              <Route path="/messages" render={() => <Messages />} />
              <Route path="/user/:uid" render={() => <User />} />
              <Route path="/conversation/:uid" render={() => <Conversation />} />
              <Route path="/" render={() => <Main />} />
            </Switch>
          {/*</div>*/}
        {/*</div>*/}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, language, conversation }) => ({ auth, language, conversation });

const mapDispatchToProps = {
  checkEvtStatus,
  resetEvtStatus,
  login,
  changeLanguage,
  openStream,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
