import React from 'react';

function ProfileIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 32 40">
      <path id="profile-icon" fill="#cacaca" d="M2315.6,2092a12.2,12.2,0,0,0,5.28-9.37,10.883,10.883,0,0,0-21.76,0,12.2,12.2,0,0,0,5.28,9.37c-5.71,1.76-10.4,6.36-10.4,12.5v7.5h32v-7.5C2326,2098.36,2321.31,2093.76,2315.6,2092Zm-14.4-9.6c0-4.48,4.21-8.4,8.8-8.4s8.8,3.92,8.8,8.4A8.8,8.8,0,1,1,2301.2,2082.4ZM2324,2110h-28v-5c0-6.2,6.65-12,13-12h2c6.35,0,13,5.8,13,12v5Z" transform="translate(-2294 -2072)" />
    </svg>
  );
}

export default ProfileIcon;
