import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { SUB_COST } from '../../constants/index';

import Loader from '../Loader/Loader';
import Popup from '../Popup/Popup';

import './Sub.scss';
import subImg from '../../images/sub-image.png';
import { subscribe } from '../../actions/auth';

class Sub extends Component {
  static propTypes = {
    auth: PropTypes.shape({
      isLoggedIn: PropTypes.bool,
    }),
  };

  render() {
    const { isLoggedIn, isSubSend } = this.props.auth;
    const { subscribe } = this.props;
    const { isPopupOpen } = this.props.popup;

    if (isLoggedIn) return <Redirect to="/" />;

    return (
      <div className="sub">
        {!isSubSend && <Loader />}

        <p className="sub__title">
          Знакомства с МТС
        </p>
        <button type="button" className="button button--red sub__button" onClick={subscribe}>
          Войти
        </button>
        <img src={subImg} alt="Подписка" className="sub__img" />
        <div className="sub__footer">
          Первые 5 дней бесплатно, далее
          {SUB_COST}
          .
          <br />
          <Link to="oferta" className="sub__link">Пользовательское соглашение</Link>
        </div>

        {isPopupOpen && <Popup />}
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
  popup,
}) => ({
  auth,
  popup,
});

const mapDispatchToProps = {
  subscribe,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Sub));
