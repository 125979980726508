import Axios from 'axios';

const loadInfo = (url, dispatch, action) => {
  Axios.get(url, { withCredentials: true })
    .then((res) => {
      if (res.status >= 400) throw new Error(res);
      return res.data;
    })
    .then((data) => {
      dispatch({
        type: action.onSuccess,
        payload: data,
      });
    })
    .catch((error) => {
      console.error('---', error);
      dispatch({
        type: action.onError,
        payload: { error },
      });
    });
};

export default loadInfo;
