import React, { Component } from 'react';
import ReactDOM from 'react-dom';

function closeComponent(ClosingComponent) {
  return class CloseComponent extends Component {
    componentDidMount() {
      window.addEventListener('click', this.handleEvent, false);
    }

    componentWillUnmount() {
      window.removeEventListener('click', this.handleEvent, false);
    }

    handleEvent = (evt) => {
      const targetElement = ReactDOM.findDOMNode(this.targetRef); // eslint-disable-line
      if (targetElement !== undefined && !targetElement.contains(evt.target)) {
        this.targetRef.closeComponent();
      }
    };

    getTargetRef = ref => (this.targetRef = ref);

    render() {
      return <ClosingComponent ref={this.getTargetRef} {... this.props} />;
    }
  };
}

export default closeComponent;
