import React from 'react';

function MessagesIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 40 40">
      <path fill="#cacaca" d="M2310,2228.01c-11.03,0-20,8.11-20,18.07a17.561,17.561,0,0,0,7.29,13.97,11.743,11.743,0,0,1-2.86,4.46,2.076,2.076,0,0,0-.47,2.2,1.867,1.867,0,0,0,1.75,1.28,1.368,1.368,0,0,0,.28-0.02,20.878,20.878,0,0,0,9.33-4.38,25.569,25.569,0,0,0,4.68.57c11.03,0,20-8.11,20-18.08S2321.03,2228.01,2310,2228.01Zm0,33.47a24.583,24.583,0,0,1-4.73-.64l-0.61-.14-0.47.43a19.493,19.493,0,0,1-6.65,3.74,12.917,12.917,0,0,0,2.44-5.16l0.15-.89-0.71-.48a14.734,14.734,0,0,1-6.92-12.26c0-8.49,7.85-15.39,17.5-15.39s17.5,6.9,17.5,15.39S2319.65,2261.48,2310,2261.48Z" transform="translate(-2290 -2228)" />
    </svg>
  );
}

export default MessagesIcon;
