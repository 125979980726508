import React from 'react';

function SearchIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 40 40">
      <path fill="#cacaca" d="M2317.71,2353.93a15.755,15.755,0,1,0-1.78,1.78l12.29,12.29,1.78-1.78Zm-11.97,3.02a13.215,13.215,0,1,1,13.21-13.21A13.232,13.232,0,0,1,2305.74,2356.95Z" transform="translate(-2290 -2328)" />
    </svg>
  );
}

export default SearchIcon;
